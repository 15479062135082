import moment from 'moment-timezone';
import {
  slotElapsed,
  signupOptionIsBeforeToday,
  signupOptionIsToday,
  entryHasProductLink,
  getSpecificSignup,
} from '~/SignUpSheets/utils/misc';
import {selectIsDateTimeBlueprint} from '../blueprint/selectors';

const isDateTimeExpired = ({sheet}, {uuid: a} = {}) => {
  const sop = sheet.signup_options.find(({uuid: b} = {}) => a === b) || {};
  if (signupOptionIsBeforeToday(sop)) return true;
  if (signupOptionIsToday(sop) && !sop.all_day && sop.slots?.every(slotElapsed)) return true;
  return false;
};

export const selectSheetId = ({sheet}) => sheet.id;
export const selectBackground = ({sheet}) => sheet.background_image;
export const selectTitle = ({sheet}) => sheet.title;
export const selectStartDate = ({sheet}) => sheet.start;
export const selectEndDate = ({sheet}) => sheet.end;
export const selectLocation = ({sheet}) => sheet.location;
export const selectAddress = ({sheet}) => sheet.address;
export const selectIsOrganizer = ({sheet, user}) =>
  !!user.userId && sheet.organizer_id === user.userId;
export const selectOrganizerName = ({sheet}) => sheet.organizer_name;
export const selectVolunteerMessage = ({sheet}) => sheet.message;
export const selectOrganizerEmail = ({sheet}) => sheet.organizer_email;
export const selectOrganizerPhone = ({sheet}) => sheet.organizer_phone;
export const selectWishlistOption = ({sheet}) => sheet.wishlist_option;
export const selectWishlistName = ({sheet}) => sheet.wishlist_name;
export const selectWishlists = ({sheet}) => sheet.wishlists;
export const selectLocalWishlists = ({sheet}) => sheet.localWishlists;
export const selectIsPreviewMode = ({sheet}) => sheet.previewMode;
export const selectSheetStatus = ({sheet}) => sheet.status;
export const selectIsInEditMode = ({sheet}) => sheet.status === 'published';
export const selectIsPristine = ({sheet}) => sheet.pristine;
export const selectSignupOptions = ({sheet}) => sheet.signup_options;
export const selectSignupOptionUuids = ({sheet}) => sheet.signup_options.map((so) => so.uuid);
export const selectNumSignupOptions = ({sheet}) => sheet.signup_options.length;
export const selectNumSignupSlots = ({sheet}) =>
  sheet.signup_options.flatMap((so) => so.slots ?? []).length;
export const selectSignupOptionById =
  (uuid) =>
  ({sheet}) =>
    sheet.signup_options.find((so) => so.uuid === uuid);
export const selectSignupOptionByIndex =
  (index) =>
  ({sheet}) =>
    sheet.signup_options[index];
export const selectHasUnsavedWishlists = ({sheet}) => sheet.has_unsaved_wishlists;
export const selectErrors = ({sheet}) => sheet.errors;
export const selectShowValidationModal = ({sheet}) => sheet.show_validation_modal;
export const selectSheetTimezone = ({sheet}) => sheet.timezone;
export const selectSignups = ({sheet}) => sheet.signups;
export const selectNumSignups = ({sheet}) => sheet.signups.length;
export const selectYourSignups =
  (userId) =>
  ({sheet}) =>
    !userId ? [] : sheet.signups.filter((s) => !!s.user_id && userId === s.user_id);
export const selectSignupsById =
  (id) =>
  ({sheet}) =>
    sheet.signups.filter((s) => getSpecificSignup(s, id));
export const selectSignupsByUuid =
  (uuid) =>
  ({sheet}) =>
    sheet.signups.filter((s) => s.signup_option === uuid);
export const selectSignupsBySlotId =
  (slotId) =>
  ({sheet}) =>
    sheet.signups.filter((s) => s.slot_id === slotId);
export const selectIsLastSignupOption =
  (index) =>
  ({sheet}) =>
    index === sheet.signup_options.length - 1;
export const selectIsOnlySignupOption = ({sheet}) => sheet.signup_options.length <= 1;
export const selectSelectedSignups = ({sheet}) => sheet.selected_signups;
export const selectSignupsPendingDeletion = ({sheet}) => sheet.signups_pending_deletion;
export const selectExpired = ({sheet}, {uuid: a} = {}) => {
  if (sheet.status === 'draft') return false;
  switch (sheet.sheet_type) {
    case 'items':
      return new Date(sheet.start) < Date.now();
    case 'date-slots': {
      const now = moment();
      if (!a) {
        // Not checking for a specific slot, but for all in general
        return sheet.signup_options.every((sop) => moment(sop.date).isBefore(now, 'day'));
      }

      const sop = sheet.signup_options.find(({uuid: b} = {}) => a === b) || {};
      return moment(sop.date).isBefore(now, 'day');
    }
    case 'datetime-slots': {
      return isDateTimeExpired({sheet}, {uuid: a});
    }
    case 'datetime-slots-with-quantity': {
      return isDateTimeExpired({sheet}, {uuid: a});
    }
    default:
      return false;
  }
};

export const selectSheetHasProducts = ({sheet}) =>
  sheet.signup_options.some(
    (so) => entryHasProductLink(so) || so.slots?.some((slot) => entryHasProductLink(slot))
  );

export const selectProductLinks = ({sheet, blueprint}) => {
  const links = {};
  const isDatetimeType = selectIsDateTimeBlueprint({blueprint});
  for (const so of sheet.signup_options) {
    if (entryHasProductLink(so)) links[so.uuid] = so.product_link.url;
    else if (isDatetimeType) {
      for (const slot of so.slots ?? []) {
        if (entryHasProductLink(slot)) links[slot.slot_id] = slot.product_link.url;
      }
    }
  }
  return links;
};

export const selectThemeId = ({sheet}) => sheet.theme_id;
export const selectActiveTheme = ({sheet: s, blueprint: bp}, {theme_id: themeId}) =>
  (s.theme_id || bp.theme_id) === themeId;

export const selectSheetIsPublished = (s) => selectIsInEditMode(s);
export const selectShowEllipses = (s) => !selectExpired(s);
export const selectCalendarLinks = ({sheet}) => sheet.calendar_links ?? {};
export const selectShortLink = ({sheet}) => {
  if (!sheet.short_link) return null;
  const url = new URL(sheet.short_link, sheet.shortlink_host);
  return url.toString();
};
export const selectSheetType = ({sheet}) => sheet?.sheet_type;

// Multiple Dates Modal
const selectMultipleDatesModalData = ({sheet}) => sheet?.multipleDatesModal;

export const selectIsMultipleDatesModalOpen = (state) => selectMultipleDatesModalData(state)?.open;

export const selectMultipleDatesModalTab = (state) => selectMultipleDatesModalData(state)?.tab;

export const selectMultipleDatesModalDates = (state) => selectMultipleDatesModalData(state)?.dates;

export const selectMultipleDatesModalStartDate = (state) =>
  selectMultipleDatesModalData(state)?.startDate;

export const selectMultipleDatesModalEndDate = (state) =>
  selectMultipleDatesModalData(state)?.endDate;

export const selectMultipleDatesModalFrequency = (state) =>
  selectMultipleDatesModalData(state)?.frequency;

export const selectMultipleDatesModalDays = (state) => selectMultipleDatesModalData(state)?.days;

export const selectMultipleDatesModalWeeklyScheduleSpecifier = (state) =>
  selectMultipleDatesModalData(state)?.weeklyScheduleSpecifier;

export const selectMultipleDatesModalMonthlyScheduleSpecifier = (state) =>
  selectMultipleDatesModalData(state)?.monthlyScheduleSpecifier;

export const selectMultipleDatesModalMonthlyScheduleSpecifierOrdinal = (state) =>
  selectMultipleDatesModalMonthlyScheduleSpecifier(state)?.ordinal;

export const selectMultipleDatesModalMonthlyScheduleSpecifierDay = (state) =>
  selectMultipleDatesModalMonthlyScheduleSpecifier(state)?.day;

// Add Multiple slots modal
export const selectMultipleSlotsModalStartTime = ({sheet}) => sheet.multipleSlotsModal.startTime;
export const selectMultipleSlotsModalEndTime = ({sheet}) => sheet.multipleSlotsModal.endTime;
export const selectMultipleSlotsModalTimeIncrementValue = ({sheet}) =>
  sheet.multipleSlotsModal.timeIncrementValue;
export const selectMultipleSlotsModalIntervalUnit = ({sheet}) =>
  sheet.multipleSlotsModal.intervalUnit;
export const selectMultipleSlotsModalSignupEachSlot = ({sheet}) =>
  sheet.multipleSlotsModal.signupEachSlot;
export const selectMultipleSlotsModalIntervalCount = ({sheet}) =>
  sheet.multipleSlotsModal.intervalCount;
export const selectMultipleSlotsModalSlots = ({sheet}) => sheet.multipleSlotsModal.slots;
