import moment from 'moment-timezone';
import {v4} from 'uuid';
import {getTime30MinsLater} from './misc';
import {BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY} from '../reducers/blueprint/constants';

export const initializeSlot = (blueprintType, slots, defaultValue, copied = false) => {
  const slotId = v4();
  let defaultStart = null;
  let defaultEnd = null;

  if (slots && slots.length > 0 && slots[slots.length - 1].end_time) {
    defaultStart = slots[slots.length - 1].end_time;
    defaultEnd = getTime30MinsLater(defaultStart);
  }

  if (defaultValue) {
    return {
      ...defaultValue,
      slot_id: slotId,
      key: slotId, // needed for animations
      copied,
    };
  }
  return {
    slot_id: slotId,
    start_time: defaultStart,
    end_time: defaultEnd,
    key: slotId, // needed for animations
    quantity: blueprintType === BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY ? 1 : undefined,
    description: blueprintType === BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY ? '' : undefined,
    copied,
  };
};

export const duplicateSlot = (
  slot,
  allDaySrc = !!slot.all_day,
  allDayTarget = !!slot.all_day,
  copied = false
) => {
  const slotId = v4();
  return {
    ...slot,
    start_time: allDayTarget || (allDaySrc && !allDayTarget) ? null : slot.start_time,
    end_time: allDayTarget || (allDaySrc && !allDayTarget) ? null : slot.end_time,
    pasted_from_all_day: allDaySrc,
    slot_id: slotId,
    key: slotId,
    copied,
  };
};

const getDefaultValue = (schema, blueprintType, defaultValue, copied = false) => {
  switch (schema.type) {
    case 'number': {
      return defaultValue || 1;
    }
    case 'date': {
      return defaultValue || null;
    }
    case 'slots': {
      return defaultValue?.length > 0
        ? defaultValue.map((v) => initializeSlot(blueprintType, undefined, v, copied))
        : [initializeSlot(blueprintType, [{end_time: '10:00 AM'}], undefined, copied)]; // "end" time of 10:00 AM so it initializes the new slot to 10:00 AM
    }
    case 'link': {
      return defaultValue || {url: '', display_text: ''};
    }
    default: {
      return defaultValue || '';
    }
  }
};

export const schemaToJson = (schema, blueprintType, scope, defaultValues = {}, copied = false) => {
  const json = {};
  json.uuid = v4();
  schema
    .filter((s) => s.scope === scope)
    .forEach((s) => {
      json[s.name] = getDefaultValue(s, blueprintType, defaultValues[s.name], copied);
    });
  json.new = true;
  json.copied = copied;
  if (blueprintType === BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY) {
    json.all_day = Boolean(defaultValues.all_day) ?? true;
    json.title = defaultValues.title;
    json.date = defaultValues.date ?? moment().format('YYYY-MM-DD');
    json.description = defaultValues.description;
  }
  return json;
};
