import {useCallback, useMemo} from 'react';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {FilledSlot} from './FilledSlot';

import './FilledSlots.sass';

export const FilledSlots = ({titleElement, slots = {}, signedInId, newUserId}) => {
  const isMatchingUserChecker = useCallback(
    (slot) => (signedInId ? slot.user_id === signedInId : slot.user_id === newUserId),
    [signedInId, newUserId]
  );

  const sortedSlots = useMemo(() => {
    const slotsToSorted = Object.values(slots);
    slotsToSorted.sort((a, b) => {
      const aIsMatchingUser = isMatchingUserChecker(a);
      const bIsMatchingUser = isMatchingUserChecker(b);
      if (aIsMatchingUser && !bIsMatchingUser) return -1;
      if (bIsMatchingUser && !aIsMatchingUser) return 1;
      return 0;
    });
    return slotsToSorted;
  }, [slots, isMatchingUserChecker]);

  const sortedSlotsGroupedByUser = useMemo(
    () =>
      sortedSlots.reduce((acc, curr) => {
        const currentUserId = curr.user_id;
        return {
          ...acc,
          [currentUserId]: {
            name: curr.name,
            email: curr.email,
            signups: (acc[currentUserId]?.signups || 0) + 1,
            comment: curr.comment,
            isMatchingUser: isMatchingUserChecker(curr),
          },
        };
      }, {}),
    [sortedSlots, isMatchingUserChecker]
  );

  if (slots?.length === 0) {
    return null;
  }

  return (
    <div className="filled-slots__container">
      {titleElement !== undefined ? (
        titleElement
      ) : (
        <Typography variant="label2" size="smaller" className="filled-slots__title">
          Who else is signed up?
        </Typography>
      )}
      {Object.entries(sortedSlotsGroupedByUser).map(
        ([userId, {name, comment, email, isMatchingUser, signups}]) => (
          <FilledSlot
            key={userId}
            name={name}
            comment={comment}
            email={email}
            matchingUser={isMatchingUser}
            signups={signups}
          />
        )
      )}
    </div>
  );
};
