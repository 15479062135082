import {createContext, useMemo, useState} from 'react';

const defaultContextValue = {
  setCanvas: () => null,
  canvas: null,
  cardDesignRef: null,
  activeObjects: [],
  setActiveObjects: () => null,
  setCardDesignRef: () => null,
};

export const EventCreateContext = createContext(defaultContextValue);

export const EventCreateParent = ({children}) => {
  const [canvas, setCanvas] = useState();
  const [cardDesignRef, setCardDesignRef] = useState();
  const [activeObjects, setActiveObjects] = useState();

  const context = useMemo(
    () => ({
      canvas,
      setCanvas,
      activeObjects,
      setActiveObjects,
      cardDesignRef,
      setCardDesignRef,
    }),
    [canvas, setCanvas, activeObjects, setActiveObjects, cardDesignRef, setCardDesignRef]
  );

  return <EventCreateContext.Provider value={context}>{children}</EventCreateContext.Provider>;
};
