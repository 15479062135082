/* eslint-disable max-lines-per-function */
import {isEqual} from 'lodash';
import {useCallback, useContext} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useRequestLogin} from '~/common/fabric/Card/components/LoginModal/hooks/useRequestLogin';
import {history, inSignupSheetMaker} from '~/SignUpSheets/history';
import {getLoggedUserId} from '~/common/fabric/User/reducers/user';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {PB_AlertLine as AlertLineIcon} from '~/common/svg/PB_AlertLine';
import {
  selectExpired,
  selectIsInEditMode,
  selectSheetId,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {
  useCancelSheetMutation,
  useLazyGetSheetQuery,
  usePublishSheetMutation,
  useSaveSheetUpdatesMutation,
} from '~/SignUpSheets/api/sheet';
import {updateUser} from '~/common/auth/utils';
import {
  SET_SHEET,
  UPDATE_SHEET_DETAILS,
  UPDATE_ERRORS,
  TOGGLE_SHOW_VALIDATION_MODAL,
} from '~/SignUpSheets/reducers/sheet/constants';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {parseErrors} from '~/SignUpSheets/utils/validation';
import {html2Markdown, markdown2Html} from '../utils/parser';
import {useAffiliateActions} from './useAffiliateActions';

const handleSheetSaveError = (response) => {
  toastAlert(response.error.data?.message || 'Failed to save sheet', {
    icon: <AlertLineIcon ratio={1.5} />,
    type: 'error',
    position: 'top-right',
  });
  return false;
};

const handleSheetGetError = (response) => {
  const msg = response.error.data?.error?.data?.detail || response.error.data?.message;
  toastAlert(msg || 'Unable to get sheet', {
    icon: <AlertLineIcon ratio={1.5} />,
    type: 'error',
    position: 'top-right',
  });
  return false;
};

export const useSheetActions = () => {
  const store = useStore();
  const location = useLocation();
  const {prevStateSheet, setPrevStateSheet} = useContext(SignUpSheetContext);
  const dispatch = useDispatch();
  const {requestLogin} = useRequestLogin();
  const isLoggedIn = useSelector(getLoggedUserId);
  const isInEditMode = useSelector(selectIsInEditMode);
  const sheetId = useSelector(selectSheetId);
  const [
    saveSheetChanges,
    {isLoading: isSavingSheet, isSuccess: isSaveSuccess, isError: isSaveError},
  ] = useSaveSheetUpdatesMutation({
    fixedCacheKey: 'shared-save-sheet-draft',
  });
  const [
    publishSheet,
    {isLoading: isPublishingSheet, isSuccess: isPublishSuccess, isError: isPublishError},
  ] = usePublishSheetMutation();
  const {processProductLinks} = useAffiliateActions();
  const [getSheet] = useLazyGetSheetQuery();
  const [doCancelSheet] = useCancelSheetMutation();
  const path =
    location.pathname.match(/\/(?<path>[a-z]+$)/)?.groups.path ||
    (inSignupSheetMaker ? 'create' : '');

  const getSavedSheet = useCallback(
    async (id) => {
      const {sheet} = store.getState();
      const susLanding = `/signup-sheets`;

      let response = await getSheet(id ?? sheet?.id ?? window.sheet_id);
      if (response?.error) {
        if (response.error.status === 401 && isLoggedIn) {
          // JWT is outdated or missing, but user is logged in, so update user data and try again
          try {
            await updateUser(store);
            response = await getSheet(sheet.id);
            if (response?.error) {
              if (response.error.status === 404) {
                // Signup sheet not found (could be that it's a draft and the user is not the organizer); redirect to SuS landing
                window.location.href = susLanding;
                return false;
              }
              return handleSheetGetError(response);
            }
          } catch (err) {
            // Unable to update user, so need to proceed as if unauthorized since JWT is expired
            return handleSheetGetError({
              error: {
                status: 401,
              },
            });
          }
        } else if (response.error.status === 404) {
          // Signup sheet not found (could be that it's a draft and the user is not the organizer); redirect to SuS landing
          window.location.href = susLanding;
          return false;
        } else {
          // Other error
          return handleSheetGetError(response);
        }
      }
      const {data} = response.data;
      // Get affiliate URL data, keeping going if the API call fails
      const past = selectExpired({sheet: data});
      try {
        if (!past) await processProductLinks(data.signup_options);
      } catch (err) {
        evite.error(err);
      }

      // Check for access to sheet and redirect or allow accordingly
      const {user} = store.getState();
      const isOrganizer = data.organizer_id === user.userId;
      const susCustomize = `/signup-sheet-maker/${data.id}/customize`;
      const susView = `/signup-sheet/${data.id}`;
      if (inSignupSheetMaker) {
        switch (path) {
          case 'customize': {
            if (data.status === 'draft') {
              if (isOrganizer) break;
              window.location.href = susLanding;
              return false;
            }
            window.location.href = susView;
            return false;
          }
          case 'edit': {
            if (data.status === 'draft') {
              window.location.href = isOrganizer ? susCustomize : susLanding;
              return false;
            }
            if (data.status === 'published' && isOrganizer && !past) {
              break;
            }
            window.location.href = susView;
            return false;
          }
          case 'success': {
            if (data.status === 'draft') {
              window.location.href = isOrganizer ? susCustomize : susLanding;
              return false;
            }
            if ((data.status === 'published' || past) && isOrganizer) {
              break;
            }
            window.location.href = susView;
            return false;
          }
          default: {
            break;
          }
        }
      } else {
        switch (path) {
          case '': {
            if (data.status === 'draft') {
              window.location.href = isOrganizer ? susCustomize : susLanding;
              return false;
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      dispatch({
        type: SET_SHEET,
        payload: data,
      });
      setPrevStateSheet(data);
      return true;
    },
    [
      isLoggedIn,
      requestLogin,
      getSheet,
      dispatch,
      setPrevStateSheet,
      path,
      store,
      processProductLinks,
    ]
  );

  const saveAndPublish = useCallback(
    async (sheet) => {
      try {
        const {
          data: {data},
        } = await saveSheetChanges(sheet);
        dispatch({
          type: UPDATE_SHEET_DETAILS,
          payload: {
            ...data,
            message: markdown2Html(data.message),
          },
        });
        return await publishSheet(sheet);
      } catch (err) {
        return err?.response;
      }
    },
    [saveSheetChanges, publishSheet]
  );

  const onSave = useCallback(
    async (finish = false) => {
      if (isSavingSheet) {
        evite.log('Previous save attempt has not completed.');
        return false;
      }
      const {
        sheet: {
          pristine,
          has_unsaved_wishlists: hasUnsavedWishlists,
          errors: existingErrors,
          show_validation_modal: showValidationModal,
          signups,
          ...body
        },
      } = store.getState();
      if (!finish && isEqual(body, prevStateSheet)) {
        return false;
      }
      const saveFn = finish ? saveAndPublish : saveSheetChanges;

      if (finish) {
        window.saveUTMToObj({sheet_id: body.id, inCreate: !isInEditMode}); // codespell:ignore
      }
      const sheetToSubmit = {
        ...body,
        utm: window.getUTM(),
        message: html2Markdown(body.message),
        signup_options: body.signup_options.map((i, idx) => ({
          ...i,
          position_index: idx,
          slots: i.slots?.map((slot, pos) => ({
            ...slot,
            position_index: pos,
          })),
        })),
      };
      let response = await saveFn(sheetToSubmit);
      if (response?.error) {
        if (response.error.status === 401 && isLoggedIn) {
          // JWT is outdated or missing, but user is logged in, so update user data and try again
          try {
            await updateUser(store);
            response = await saveFn(sheetToSubmit);
            if (response?.error) {
              return handleSheetSaveError(response);
            }
          } catch (err) {
            // Unable to update user, so need to proceed as if unauthorized since JWT is expired
            return handleSheetSaveError({
              error: {
                status: 401,
              },
            });
          }
        } else if (response.error.status === 422) {
          const errors = response.error.data?.error?.data?.detail ?? [];
          const {savingErrors, validationErrors} = parseErrors(errors);
          if (savingErrors.length > 0) {
            const message = savingErrors.pop();
            return handleSheetGetError({error: {data: {message}}});
          }
          // Sheet changes may not have been saved, but position indexes of signup options need to be updated,
          // so run UPDATE_SHEET_DETAILS with updated position indexes before saving validation errors to Redux
          dispatch({
            type: UPDATE_SHEET_DETAILS,
            payload: {
              pristine: false,
              signup_options: sheetToSubmit.signup_options.map((so, i) => ({
                ...so,
                position_index: i,
              })),
            },
          });
          dispatch({
            type: UPDATE_ERRORS,
            payload: validationErrors,
          });
          dispatch({
            type: TOGGLE_SHOW_VALIDATION_MODAL,
            payload: true,
          });
          return false;
        } else {
          // Other error
          return handleSheetSaveError(response);
        }
      }

      const {data} = response.data;

      if (!sheetId) {
        window.dataLayer.push({
          event: 'sus_draft_created',
          event_id: data.id,
          user_id: data.organizer_id,
        });
      }

      dispatch({
        type: UPDATE_ERRORS,
        payload: [],
      });
      if (finish) {
        dispatch({
          type: UPDATE_SHEET_DETAILS,
          payload: {
            ...data,
            message: markdown2Html(data.message),
          },
        });
      } else {
        // Still save sheet ID
        dispatch({
          type: UPDATE_SHEET_DETAILS,
          payload: {
            id: data.id,
          },
        });
      }
      if (history.location.pathname.includes('/create')) {
        history.replace({
          pathname: `/${data.id}/customize`,
          hash: history.location.hash,
          search: history.location.search,
        });
      } else if (finish) {
        history.push({
          pathname: `/${data.id}/success`,
          hash: history.location.hash,
          search: history.location.search,
        });
      } else {
        setPrevStateSheet(data);
      }

      return data;
    },
    [
      isLoggedIn,
      requestLogin,
      saveSheetChanges,
      saveAndPublish,
      dispatch,
      history,
      prevStateSheet,
      setPrevStateSheet,
      isSavingSheet,
      isInEditMode,
    ]
  );

  const autoSave = useCallback(
    async (finish = false, alreadyLoggedIn = false) => {
      // alreadyLoggedIn is a hack, since requestLogin does not update the state,
      // so calling onSave after requestLogin won't update isLoggedIn here, so
      // the login modal will reappear/stay-open.
      if (!alreadyLoggedIn && !isLoggedIn) {
        const loggedIn = await requestLogin();

        if (!loggedIn) {
          return false;
        }
      }
      return (
        !isInEditMode &&
        new Promise((resolve, reject) => {
          onSave(finish).then(resolve).catch(reject);
        })
      );
    },
    [onSave, isLoggedIn, requestLogin, isInEditMode]
  );

  const cancelSheet = useCallback(
    async (id) => {
      try {
        const response = await doCancelSheet(id);
        const {data} = response.data;
        dispatch({
          type: UPDATE_SHEET_DETAILS,
          payload: {
            ...data,
            message: markdown2Html(data.message),
          },
        });
        toastAlert('Your sheet has been canceled.', {
          type: 'success',
          position: 'top-right',
        });
      } catch (err) {
        toastAlert('Failed to cancel sheet.', {
          icon: <AlertLineIcon ratio={1.5} />,
          type: 'error',
          position: 'top-right',
        });
      }
    },
    [doCancelSheet]
  );

  const editSheet = useCallback(() => {
    if (!sheetId) return;
    window.location.href = `/signup-sheet-maker/${sheetId}/edit`;
  }, [sheetId]);

  return {
    getSavedSheet,
    autoSave,
    onSave,
    cancelSheet,
    editSheet,
    isSavingSheet,
    isSaveSuccess,
    isSaveError,
    isPublishingSheet,
    isPublishSuccess,
    isPublishError,
  };
};
