import {silver} from '~sass/pb_styleguide/base/_exports.sass';

export const selectFabricArt = ({fabricDesign}) => fabricDesign.fabricArt;

export const selectFabricArtBackgroundImage = ({fabricDesign}) =>
  fabricDesign.fabricArt?.backgroundImage;

export const selectActiveCardEditor = ({fabricDesign}) => fabricDesign.activeCardEditor;

export const selectEnvelopeStickers = ({fabricDesign}) => fabricDesign.envelopeStickers;

export const selectOriginalFabricArt = ({fabricDesign}) => fabricDesign.originalFabricArt;

export const selectBackgroundId = ({fabricDesign}) => fabricDesign.backgroundId;

export const selectBackgroundColor = ({fabricDesign}) => fabricDesign.backgroundColor;

export const selectStampId = ({fabricDesign}) => fabricDesign.stampId;

export const selectEnvelopeId = ({fabricDesign}) => fabricDesign.envelopeId || 'wht01';

export const selectLinerId = ({fabricDesign}) => fabricDesign.linerId;

export const selectCurrentSection = ({fabricDesign}) => fabricDesign.currentSection;

export const selectLoaded = ({fabricDesign}) => fabricDesign?.loaded ?? false;

export const selectTouched = ({fabricDesign}) => fabricDesign.touched;

export const selectPristine = ({fabricDesign}) => fabricDesign.pristine;
export const selectPremiumFeaturesWarning = ({fabricDesign}) => fabricDesign.premiumFeaturesWarning;

export const selectIgnorePremiumWarning = ({fabricDesign}) => fabricDesign.ignorePremiumWarning;

export const selectPristineUyo = ({fabricDesign}) => fabricDesign.pristineUyo;

export const selectIsPristineUyo = ({fabricDesign}) => {
  if (typeof fabricDesign.pristineUyo === 'object') {
    return Object.values(fabricDesign.pristineUyo).some((v) => v === true);
  }
  return fabricDesign.pristineUyo;
};

export const selectCountOfUyoImages = ({fabricDesign, eventDetails}) => {
  const objects =
    fabricDesign.originalFabricArt?.objects || eventDetails.fabric_design_data?.objects;
  return objects ? objects.filter((obj) => obj.type === 'uYOImage').length : 0;
};

export const selectPristineBackground = ({fabricDesign}) => fabricDesign.pristineBackground;
export const selectAllFieldsEdited = ({fabricDesign}) => fabricDesign.allFieldsEdited;

export const selectHasTextbox = ({fabricDesign}) => fabricDesign.hasTextbox;

export const selectPremiumFeaturesPristine = ({fabricDesign}) =>
  fabricDesign.premiumFeaturesPristine;

export const selectSavingStep = ({fabricDesign}) => fabricDesign.savingStep;

export const selectIsUyo = ({fabricDesign, eventDetails}) => {
  const objects =
    fabricDesign.originalFabricArt?.objects || eventDetails.fabric_design_data?.objects;
  return objects && objects.some((obj) => obj.type === 'uYOImage');
};

export const selectIsFilledUyo = ({fabricDesign, eventDetails}) => {
  const objects = fabricDesign.fabricArt?.objects || eventDetails.fabric_design_data?.objects;
  return objects && objects.some((obj) => obj.type === 'uYOImage' && obj.src);
};

export const selectIsFullDyo = ({fabricTemplate}) =>
  fabricTemplate.templateName.includes('general-full') &&
  fabricTemplate.templateName.includes('dyo');

export const selectTextAreaOpen = ({fabricDesign}) => fabricDesign.textAreaOpen;

export const selectEditingMobile = ({fabricDesign}) => fabricDesign.editingMobile;

export const selectEditorDisabled = ({fabricDesign}) => fabricDesign.editorDisabled;

export const selectEditingUyoMobile = ({fabricDesign}) => fabricDesign.editingUyoMobile;

export const selectForceShowOnboardingModal = ({fabricDesign}) =>
  fabricDesign.forceShowOnboardingModal;

export const selectSelectedEnvelope = (state) => {
  const {envelopeId} = state.fabricDesign;
  if (
    !envelopeId ||
    !state?.fabricTemplate?.allEnvelopes ||
    state?.fabricTemplate?.allEnvelopes?.length === 0
  )
    return undefined;
  return (
    state.fabricTemplate.allEnvelopes.find((envelope) => envelope.id === envelopeId) ??
    state.fabricTemplate?.allEnvelopes?.[0]
  );
};

export const selectSelectedStamp = (state) => {
  const {stampId} = state.fabricDesign;
  if (
    !stampId ||
    !state?.fabricTemplate?.allStamps ||
    state?.fabricTemplate?.allStamps?.length === 0
  )
    return undefined;
  return (
    state.fabricTemplate.allStamps.find((stamp) => stamp.id === stampId) ??
    state.fabricTemplate?.allStamps?.[0]
  );
};

export const selectSelectedLiner = (state) => {
  const {linerId} = state.fabricDesign;
  if (!linerId || !state?.fabricTemplate?.allLiners) return undefined;
  return state.fabricTemplate.allLiners.find((liner) => liner.id === linerId);
};

export const selectSelectedBackground = (state) => {
  const {backgroundId} = state.fabricDesign;
  if (!backgroundId || !state?.fabricTemplate?.allBackgrounds) return undefined;
  return state.fabricTemplate.allBackgrounds.find((bg) => bg.id === backgroundId);
};
/* eslint-enable consistent-return,no-restricted-syntax */

// If free, the original color is always '#E1E3E6' - in premium, original color comes from the template
export const selectBackgroundChanged = (state) => {
  const color = state.fabricDesign.backgroundColor;
  const hasBackgroundImage = state.fabricDesign.backgroundId;
  const originalColor = state.fabricTemplate.premium
    ? state.fabricTemplate.backgroundColor
    : silver;
  return hasBackgroundImage || color !== originalColor;
};

export const selectUsedPremiumFeature = (state) => state.fabricDesign.usedPremiumFeature;
