import {useCallback, useContext, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {HeaderOptions} from '~/SignUpSheets/components/Header/HeaderOptions';
import {HeaderActionButtons} from '~/SignUpSheets/components/Header/HeaderActionButtons';
import {HeaderLeaveButton} from '~/SignUpSheets/components/Header/HeaderLeaveButton';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {
  selectExpired,
  selectSheetId,
  selectSheetStatus,
  selectShortLink,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {mediumAndUpQuery, largishAndUp} from '~sass/pb_styleguide/base/_exports.sass';
import {ResponsiveModal} from '~/common/_pb_components/organisms/ResponsiveModal';
import {SignUpSheetsIcon} from '~/common/svg/SignUpSheetsIcon';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {PreviewToggle} from './components/PreviewToggle/PreviewToggle';

import './Header.sass';

export const Header = () => {
  const {flow} = useContext(SignUpSheetContext);
  const sheetId = useSelector(selectSheetId);
  const sheetStatus = useSelector(selectSheetStatus);
  const shortLink = useSelector(selectShortLink);
  const past = useSelector(selectExpired);
  const {matches: mdScreens} = useMatchQuery(`(${mediumAndUpQuery})`);
  const {matches: isDesktop} = useMatchQuery(`(min-width: ${largishAndUp})`);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const {cancelSheet, editSheet} = useSheetActions();

  const expired = useMemo(() => past || sheetStatus === 'canceled', [past, sheetStatus]);

  const onShareHandler = useCallback(() => {
    navigator.clipboard
      .writeText(shortLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      })
      .catch((error) => {
        evite.error('Failed to copy', error);
      });
  }, [shortLink]);

  const onConfirmCancel = useCallback(() => {
    cancelSheet(sheetId);
    setShowConfirmCancelModal(false);
  }, [sheetId]);

  const onCancelHandler = useCallback(() => {
    setShowConfirmCancelModal(true);
  }, []);

  const handleCloseConfirmCancelModal = useCallback(() => {
    setShowConfirmCancelModal(false);
  }, []);

  return (
    <div className="signup-sheet__header__wrapper">
      <div className="signup-sheet__header">
        <div className="signup-sheet__header__info">
          <HeaderLeaveButton />
          <SignUpSheetsIcon ratio={0.76} />
          {!isDesktop ? (
            <HeaderOptions
              handleEdit={editSheet}
              handleShare={onShareHandler}
              handleCancel={onCancelHandler}
              expired={expired}
            />
          ) : null}
        </div>

        {/* Show header action buttons if on desktop, or in medium screen in create/edit, and only if sheet is not expired */}
        {(isDesktop || (mdScreens && ['create', 'edit'].includes(flow))) && !expired ? (
          <HeaderActionButtons
            handleEdit={editSheet}
            handleShare={onShareHandler}
            handleCancel={onCancelHandler}
            copied={copied}
          />
        ) : null}

        {showConfirmCancelModal ? (
          <ResponsiveModal
            isOpen
            title="Cancel sheet"
            body="Your SignUp Sheet will be canceled and volunteers will be notified via email."
            primaryText="Cancel sheet"
            secondaryText="Nevermind"
            primaryAction={onConfirmCancel}
            onClose={handleCloseConfirmCancelModal}
          />
        ) : null}
      </div>

      <PreviewToggle />
    </div>
  );
};
