import {PB_CloseLine as CloseLine} from '~/common/svg/PB_CloseLine';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';

const FirstStepLeaveModal = (props) => {
  const {cancelLeave, leave} = props;
  return (
    <ConfirmLeaveModal
      {...props}
      title="Are you sure you want to Exit?"
      message="You will lose your invitation progress. Please continue to step 2 to save your invitation."
    >
      <Button
        className="create-flow__upsell-modal-upgrade-btn"
        data-qa-id="say-yes-btn"
        variant="secondary"
        size="large"
        onClick={() => leave()}
        data-impression-tracking="true"
      >
        Yes, I&apos;ll exit
      </Button>
      <Button
        className="create-flow__upsell-modal-back-btn"
        data-qa-id="say-no-btn"
        variant="primary"
        size="large"
        onClick={() => cancelLeave()}
      >
        No, I&apos;ll continue
      </Button>
    </ConfirmLeaveModal>
  );
};

const EditionFlowLeaveModal = (props) => {
  const {cancelLeave, leave} = props;
  return (
    <ConfirmLeaveModal
      {...props}
      title="Are you sure you want to cancel?"
      message="Your unsaved changes to this invitation will be lost."
    >
      <Button
        className="create-flow__upsell-modal-upgrade-btn"
        data-qa-id="say-yes-btn"
        variant="secondary"
        size="large"
        onClick={() => cancelLeave()}
        data-impression-tracking="true"
      >
        No
      </Button>
      <Button
        className="create-flow__upsell-modal-back-btn"
        data-qa-id="say-no-btn"
        variant="primary"
        size="large"
        onClick={() => leave()}
      >
        Yes
      </Button>
    </ConfirmLeaveModal>
  );
};

const CreationFlowLeaveModal = (props) => {
  const {cancelLeave, leave} = props;
  return (
    <ConfirmLeaveModal
      {...props}
      title="Exit the Invitation Editor"
      message="We've saved your progress so you can finish creating your invitation later."
    >
      <Button
        className="create-flow__upsell-modal-back-btn"
        data-qa-id="keep-editing-btn"
        variant="secondary"
        size="large"
        onClick={() => cancelLeave()}
      >
        Keep Editing
      </Button>
      <Button
        className="create-flow__upsell-modal-upgrade-btn"
        data-qa-id="exit-btn"
        variant="primary"
        size="large"
        onClick={() => leave()}
        data-impression-tracking="true"
      >
        Exit
      </Button>
    </ConfirmLeaveModal>
  );
};

export const ConfirmLeaveModal = (props) => {
  const {cancelLeave, children, message, title} = props;

  return (
    <div className="create-flow__upsell-modal">
      <div
        className="create-flow__upsell-modal-overlay"
        onClick={() => cancelLeave()}
        role="button"
        onKeyUp={cancelLeave}
        aria-label="cancel leave"
        tabIndex={-1}
      />
      <div className="create-flow__upsell-modal-content">
        <button
          className="create-flow__upsell-modal-close-btn"
          onClick={() => cancelLeave()}
          aria-label="cancel leave"
          type="button"
        >
          <CloseLine ratio={0.75} />
        </button>
        <Typography
          as="p"
          classNames="create-flow__upsell-modal-label"
          variant="label1"
          data-qa-id="createFlowUpsellModalLabel"
          color="shadow"
        >
          {title}
        </Typography>
        <Typography
          as="p"
          classNames="create-flow__upsell-modal-description"
          variant="paragraph2"
          data-qa-id="createFlowUpsellModalDescription"
          color="shadow"
        >
          {message}
        </Typography>
        <div className="create-flow__upsell-modal-footer">{children}</div>
      </div>
    </div>
  );
};

export const ConfirmLeaveModalContainer = ({
  isFirstStep,
  cancelLeave,
  saveAndLeave,
  leave,
  isEditingFlow,
}) => {
  switch (true) {
    case isFirstStep && isEditingFlow:
    case isEditingFlow:
      return <EditionFlowLeaveModal cancelLeave={cancelLeave} leave={leave} />;
    case isFirstStep:
      return <FirstStepLeaveModal cancelLeave={cancelLeave} leave={leave} />;
    default:
      return <CreationFlowLeaveModal cancelLeave={cancelLeave} leave={saveAndLeave} />;
  }
};
