/* eslint-disable max-lines-per-function */
import moment from 'moment-timezone';
import {USER_LOGGED_IN} from '~/common/components/LoginModal/reducer';
import {INIT_APP} from '~/common/fabric/constants';
import {
  SET_SHEET,
  TOGGLE_SHOW_VALIDATION_MODAL,
  UPDATE_ERRORS,
  UPDATE_SHEET_DETAILS,
  SET_SELECTED_SIGNUPS,
  UPDATE_SIGNUPS,
  INIT_SIGNUPS,
  SET_SIGNUPS,
  REMOVE_SIGNUP,
  UPDATE_MULTIPLE_DATES_MODAL,
  UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB,
  UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB,
  UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER,
  UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
  UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM,
  UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT,
  SET_SIGNUPS_PENDING_DELETION,
  DELETE_SIGNUP_OPTION,
  MOVE_SIGNUP_OPTION_UP,
  MOVE_SIGNUP_OPTION_DOWN,
} from './constants';
import {getUserTimezone} from '~/SignUpSheets/components/TimezoneSelect/TimezoneSelect';
import {sortOptionsByDate} from '~/SignUpSheets/utils/misc';
import {markdown2Html} from '~/SignUpSheets/utils/parser';
import {selectIsAutoSorting} from '../blueprint/selectors';
import {frequencyHandler, selectedDayHandler} from '~/SignUpSheets/reducers/sheet/utils';

const userTimezone = getUserTimezone();

const todaysDate = moment();

export const initialMultipleDatesModalState = {
  tab: 'custom',
  open: false,
  dates: [],
  startDate: todaysDate.format('YYYY-MM-DDTHH:mm:ss'),
  endDate: null,
  frequency: 'weekly',
  days: [todaysDate.day().toString()],
  weeklyScheduleSpecifier: '1',
  monthlyScheduleSpecifier: {
    ordinal: '1st',
    day: todaysDate.day().toString(),
  },
};

const initialMultipleSlotsModalState = {
  startTime: '9:00 AM',
  endTime: '',
  timeIncrementValue: 15,
  intervalUnit: 'minutes',
  signupEachSlot: 2,
  intervalCount: 0,
  slots: [],
};

export const initialState = {
  pristine: true,
  has_unsaved_wishlists: false,
  errors: [],
  show_validation_modal: false,
  ready: false,
  // The below should match the backend representation as closely as possible, preferably exactly
  id: null,
  blueprint_id: '',
  sheet_type: '',
  organizer_name: window.userName ?? '',
  organizer_id: window.user_id ?? '',
  title: '',
  start: null,
  end: null,
  location: null,
  address: {city: '', state: '', street: '', zip: '', country: 'US'},
  message: '',
  organizer_email: null,
  organizer_phone: null,
  background_image: null,
  wishlist_option: 'evite-wishlist',
  wishlist_name: '',
  wishlists: [''],
  localWishlists: [''],
  previewMode: false,
  created_at: null,
  status: 'draft',
  signup_options: [],
  signups: [],
  selected_signups: [],
  signups_pending_deletion: [],
  theme_id: '',
  timezone: {
    name: userTimezone.value,
    offset: userTimezone.offset,
  },
  calendar_links: {},
  short_link: null,
  shortlink_host: window.shortlink_host,
  multipleDatesModal: initialMultipleDatesModalState,
  multipleSlotsModal: initialMultipleSlotsModalState,
};

const sortSignupOptions = (signupOptions, sheetType) => {
  if (signupOptions?.length > 0 && selectIsAutoSorting({blueprint: {blueprint_type: sheetType}})) {
    return signupOptions.toSorted(sortOptionsByDate);
  }
  return signupOptions;
};

const setDaysAndDatesBasedOnSeries = (newState, prevState) => {
  const updatedState = {...newState};
  updatedState.multipleDatesModal.days = selectedDayHandler({sheet: prevState}, {sheet: newState});
  updatedState.multipleDatesModal.dates = frequencyHandler({sheet: newState});
  return updatedState;
};

export const reducer = (state = initialState, action = {}) => {
  let newState = {...state};

  switch (action.type) {
    case USER_LOGGED_IN:
      newState = {
        ...state,
        organizer_name: state.organizer_name || action.userName,
        organizer_id: state.organizer_id || action.userId,
      };
      break;
    case INIT_APP: {
      const {sheet = {}} = action.payload;
      newState = {
        ...state,
        ...sheet,
        ready: Object.keys(sheet) > 0,
      };
      break;
    }

    case INIT_SIGNUPS: {
      const signups = [];

      for (const signup of state.signups) {
        if (signup.slot_id) {
          const signupOption = state.signup_options.find(
            (so) => so.slots && so.slots.some((slot) => slot.slot_id === signup.slot_id)
          );
          signups.push({
            ...signup,
            signup_option: signupOption?.uuid,
          });
        } else {
          signups.push(signup);
        }
      }

      newState = {
        ...state,
        signups,
      };
      break;
    }

    case SET_SIGNUPS: {
      newState = {
        ...state,
        signups: action.payload ?? [],
      };
      break;
    }

    case UPDATE_SIGNUPS: {
      const lookup = action.payload.reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {});

      newState = {
        ...state,
        signups: state.signups.map((s) =>
          lookup[s.id]
            ? {
                ...s,
                id: lookup[s.id].id,
                user_id: lookup[s.id].user_id,
                comment: lookup[s.id].comment,
                email: lookup[s.id].email,
                name: lookup[s.id].name,
                slot_id: lookup[s.id].slot_id,
                purchased: lookup[s.id].purchased,
              }
            : s
        ),
      };
      break;
    }
    case REMOVE_SIGNUP: {
      const newId = Math.max(...state.signups.map((signup) => signup.id)) + 1;
      newState = {
        ...state,
        signups: state.signups.map((signup) =>
          signup.id === action.payload
            ? {
                ...signup,
                id: newId,
                user_id: '',
                name: '',
                email: '',
                comment: '',
              }
            : signup
        ),
      };
      break;
    }
    case SET_SELECTED_SIGNUPS: {
      newState = {
        ...state,
        selected_signups: action.payload,
      };
      break;
    }
    case SET_SIGNUPS_PENDING_DELETION: {
      newState = {
        ...state,
        signups_pending_deletion: action.payload,
      };
      break;
    }
    case SET_SHEET:
      newState = {
        ...state,
        ...action.payload,
        message: markdown2Html(action.payload.message ?? ''),
        ready: true,
      };
      break;
    case UPDATE_SHEET_DETAILS: {
      // Check to see that has_unsaved_wishlists is being changed
      const {appendIfArray = false, ...payload} = action.payload;
      const {has_unsaved_wishlists: hasUnsavedWishlists} = payload;

      const updateStateValues = () => {
        Object.entries(payload).forEach(([key, val]) => {
          if (appendIfArray && Array.isArray(val) && Array.isArray(newState[key])) {
            newState[key] = [...newState[key], ...val];
          } else {
            newState[key] = val;
          }
        });
      };

      if (hasUnsavedWishlists === undefined) {
        // has_unsaved_wishlists is not being changed; proceed as normal
        updateStateValues();
        break;
      }

      // Update error object to account for has_unsaved_wishlists
      const errors = [...(hasUnsavedWishlists ? [] : state.errors)];
      const showValidationModal = hasUnsavedWishlists || state.show_validation_modal;
      if (hasUnsavedWishlists) {
        const idx = errors.find((e) => e.loc?.[0] === 'wishlists');
        if (idx >= 0) {
          errors[idx].msg = 'Please finish saving your wishlists';
        } else {
          errors.push({
            type: 'value_error',
            loc: ['wishlists'],
            msg: 'Please finish saving your wishlists',
          });
        }
      } else {
        const idx = errors.find((e) => e.loc?.[0] === 'wishlists');
        if (idx >= 0) errors.splice(idx, 1);
      }

      updateStateValues();
      newState.has_unsaved_wishlists = hasUnsavedWishlists;
      newState.show_validation_modal = showValidationModal;
      newState.errors = errors;
      break;
    }
    case DELETE_SIGNUP_OPTION: {
      const {index} = action.payload;
      if (index >= 0 && index < state.signup_options.length) {
        const newSignupOptionsList = [...state.signup_options];
        newSignupOptionsList.splice(index, 1);
        let newSignups;
        const deletedSignupSlots = state.signup_options[index].slots;
        if (deletedSignupSlots) {
          const deletedSignupSlotsIds = deletedSignupSlots.map((slot) => slot.slot_id);
          newSignups = state.signups.filter(
            (signup) => !deletedSignupSlotsIds.includes(signup.slot_id)
          );
        } else {
          const deletedSignupOptionUuid = state.signup_options[index]?.uuid;
          newSignups = state.signups.filter(
            (signup) => deletedSignupOptionUuid && signup.signup_option !== deletedSignupOptionUuid
          );
        }
        newState = {
          ...state,
          signup_options: newSignupOptionsList,
          signups: newSignups,
          pristine: false,
        };
      } else {
        evite.error('Invalid index for deletion');
      }
      break;
    }
    case MOVE_SIGNUP_OPTION_UP: {
      // TODO: Prune out "move up" functionality for 1437 (Drag N Drop)
      const {index} = action.payload;
      if (index <= 0 || index >= state.signup_options.length) break;
      const newSignupOptionsList = [...state.signup_options];
      [newSignupOptionsList[index - 1], newSignupOptionsList[index]] = [
        newSignupOptionsList[index],
        newSignupOptionsList[index - 1],
      ];
      newState = {
        ...state,
        signup_options: newSignupOptionsList,
        pristine: false,
      };
      break;
    }
    case MOVE_SIGNUP_OPTION_DOWN: {
      // TODO: Prune out "move down" functionality for 1437 (Drag N Drop)
      const {index} = action.payload;
      if (index < 0 || index >= state.signup_options.length - 1) break;
      const newSignupOptionsList = [...state.signup_options];
      [newSignupOptionsList[index], newSignupOptionsList[index + 1]] = [
        newSignupOptionsList[index + 1],
        newSignupOptionsList[index],
      ];
      newState = {
        ...state,
        signup_options: newSignupOptionsList,
        pristine: false,
      };
      break;
    }
    case UPDATE_ERRORS:
      newState = {
        ...state,
        errors: [...action.payload],
      };
      break;
    case TOGGLE_SHOW_VALIDATION_MODAL:
      newState = {
        ...state,
        show_validation_modal: action.payload,
      };
      break;
    case UPDATE_MULTIPLE_DATES_MODAL:
      newState = {
        ...state,
        multipleDatesModal: {...state.multipleDatesModal, ...action.payload},
      };
      break;
    case UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB:
      newState = {
        ...state,
        multipleDatesModal: {
          ...state.multipleDatesModal,
          dates: [...action.payload],
        },
      };
      break;
    case UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB:
      newState = {
        ...state,
        multipleDatesModal: {
          ...state.multipleDatesModal,
          ...action.payload,
        },
      };
      newState = setDaysAndDatesBasedOnSeries(newState, state);
      break;
    case UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER:
      newState = {
        ...state,
        multipleDatesModal: {
          ...state.multipleDatesModal,
          monthlyScheduleSpecifier: {
            ...state.multipleDatesModal.monthlyScheduleSpecifier,
            ...action.payload,
          },
        },
      };
      newState = setDaysAndDatesBasedOnSeries(newState, state);
      break;
    case UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE:
    case UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT:
      newState = {
        ...state,
        multipleSlotsModal: {
          ...state.multipleSlotsModal,
          ...action.payload,
        },
      };
      break;
    case UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM:
      newState = {...state, multipleSlotsModal: initialMultipleSlotsModalState};
      break;
    default:
      break;
  }
  newState.signup_options = sortSignupOptions(newState.signup_options, state.sheet_type);
  return newState;
};
