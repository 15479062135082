import {useCallback, useEffect, useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  selectSelectedSignups,
  selectSheetId,
  selectSignupsPendingDeletion,
} from '../reducers/sheet/selectors';
import {
  useLazyGetSheetQuery,
  useRemoveSignupMutation,
  useSaveSignupsMutation,
  useUpdateSignupsMutation,
} from '../api/sheet';
import {validate_name_or_email as validNameOrEmail} from '~/common/utils';
import {SET_SELECTED_SIGNUPS, SET_SHEET, UPDATE_SIGNUPS} from '../reducers/sheet/constants';
import {selectIsDateTimeBlueprint} from '../reducers/blueprint/selectors';
import {parseErrors} from '~/SignUpSheets/utils/validation';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {PB_AlertLine as AlertLineIcon} from '~/common/svg/PB_AlertLine';
import {VOLUNTEER_SIGNUP} from '~/common/fabric/constants';

const handleSheetUpdateError = (message) => {
  toastAlert(message || 'Failed to update sheet', {
    icon: <AlertLineIcon ratio={1.5} />,
    type: 'error',
    position: 'top-right',
  });
  return false;
};

export const useSaveOrUpdateSignups = ({
  volunteerName,
  volunteerEmail,
  isEditing,
  setNameError,
  setEmailError,
  setSignupError,
  scope = 'signup', // 'signup' or 'view'
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sheetId = useSelector(selectSheetId);
  const [getSheet] = useLazyGetSheetQuery();
  const [saveSignups] = useSaveSignupsMutation();
  const [updateSignups] = useUpdateSignupsMutation();
  const [removeSignup] = useRemoveSignupMutation();
  const selectedSignups = useSelector(selectSelectedSignups, shallowEqual);
  const signupsPendingDeletion = useSelector(selectSignupsPendingDeletion, shallowEqual);
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const userId = useSelector((state) => state.user.userId);
  const hasAttemptedSave = useRef(false);

  const validate = () => {
    let hasError = false;
    if (validNameOrEmail('name', volunteerName)) {
      hasError = true;
      setNameError('Please enter your name');
    } else {
      setNameError('');
    }
    if (validNameOrEmail('email', volunteerEmail)) {
      hasError = true;
      setEmailError('Please enter a valid email');
    } else {
      setEmailError('');
    }
    return hasError;
  };

  const clearSelectedSignups = useCallback(() => {
    dispatch({type: SET_SELECTED_SIGNUPS, payload: []});
  }, [dispatch]);

  const handleSave = useCallback(
    async (optionsToSubmit) => {
      hasAttemptedSave.current = true;
      if (scope === 'signup' && validate()) throw new Error('validation failed');

      const signupsData = optionsToSubmit.map((signup) => ({
        signup_option: !isDatetimeType ? signup.signup_option : undefined,
        slot_id: signup.slot_id,
        user_id: userId,
        name: volunteerName,
        email: volunteerEmail,
        comment: signup.comment,
      }));

      dispatch({
        type: VOLUNTEER_SIGNUP,
        payload: {volunteerEmail, volunteerName},
      });

      const results = await saveSignups({
        id: sheetId,
        signups: signupsData,
      });

      if (results.error) {
        throw results.error;
      }

      const selectedSignupsLookup = selectedSignups.reduce((lookup, signup, index) => {
        const key = `${isDatetimeType ? signup.slot_id : signup.signup_option}-${index}`;
        return {
          ...lookup,
          [key]: signup,
        };
      }, {});
      const matchingSignups = results.data.data
        .map((resultSignup, index) => {
          const key = `${isDatetimeType ? resultSignup.slot_id : resultSignup.signup_option}-${index}`;
          const matchingSelectedSignup = selectedSignupsLookup[key];

          if (matchingSelectedSignup && volunteerEmail === resultSignup.email) {
            return {
              ...resultSignup,
              oldId: matchingSelectedSignup.id,
            };
          }

          return resultSignup;
        })
        .filter((resultSignup) => resultSignup.oldId);

      dispatch({type: UPDATE_SIGNUPS, payload: matchingSignups});
      const updatedSheet = await getSheet(sheetId);
      dispatch({type: SET_SHEET, payload: updatedSheet.data.data});
      return results;
    },
    [
      dispatch,
      saveSignups,
      validate,
      volunteerName,
      volunteerEmail,
      selectedSignups,
      getSheet,
      sheetId,
      scope,
    ]
  );

  const handleUpdateSignups = useCallback(
    async (items) => {
      const result = await updateSignups({
        id: sheetId,
        signups: items,
      });

      if (result.error?.status === 422) {
        const {savingErrors, validationErrors} = parseErrors(
          result.error.data.error.data?.detail ?? []
        );
        handleSheetUpdateError(savingErrors?.[0] || validationErrors?.[0].msg);
        return false;
      }

      const updatedItems = result.data.data.map((item) => ({
        ...item,
        comment: item.comment,
      }));

      dispatch({type: UPDATE_SIGNUPS, payload: updatedItems});

      if (result.error) {
        throw result.error;
      }
      return true;
    },
    [dispatch, updateSignups, sheetId]
  );

  const onSave = useCallback(
    async (signups = selectedSignups ?? []) => {
      try {
        let newUserId;

        const updatedSignups = signups
          .filter((s) => s.id)
          .map((s) => ({...s, email: volunteerEmail}));
        const newSignups = signups.filter((s) => !s.id);

        if (updatedSignups.length > 0) {
          // Some signups have been updated; save updates
          const success = await handleUpdateSignups(updatedSignups);
          if (!success) {
            return;
          }
        }
        if (newSignups.length > 0) {
          // Some new signups; save those
          const saveResult = await handleSave(newSignups);
          newUserId = saveResult?.data?.data[0]?.user_id;
        }
        for await (const signupToDelete of signupsPendingDeletion) {
          // Some signups have been deleted; remove them
          if (signupToDelete.user_id === userId) {
            removeSignup({id: sheetId, row_id: signupToDelete.id});
          }
        }

        const searchParams = new URLSearchParams();
        searchParams.append('uid', btoa(newUserId || userId));
        localStorage.setItem(`sheet-${sheetId}-user`, newUserId || userId);

        if (scope === 'signup') {
          clearSelectedSignups();
          history.replace(
            {
              pathname: `/${sheetId}/success`,
              search: searchParams.toString(),
            },
            {isEditing}
          );
        }
      } catch (error) {
        evite.error('Failed to save:', error);
        if (typeof error === 'object' && error?.data?.error) {
          setSignupError(error.data.error.data?.detail || {});
        }
      }
    },
    [
      isEditing,
      handleUpdateSignups,
      selectedSignups,
      signupsPendingDeletion,
      handleSave,
      userId,
      history,
      sheetId,
      clearSelectedSignups,
    ]
  );

  useEffect(() => {
    if (hasAttemptedSave.current && scope === 'signup') {
      validate();
    }
  }, [volunteerName, volunteerEmail, scope]);

  return {onSave};
};
