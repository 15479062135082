import cx from 'classnames';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import urls from 'urls';
import * as utils from '~/ViewInvitation/utils';
import {EviteModal} from '~/modal';
import {PB_CalendarLine as CalendarIcon} from '~/common/svg/PB_CalendarLine';
import {tanzanite, pebble} from '~sass/pb_styleguide/base/_exports.sass';
import {Typography} from '~/common/_pb_components/atoms/Typography';

import {useBrowserWidth} from '~/common/hooks/useBrowserWidth';
import {useBrowserHeight} from '~/common/hooks/useBrowserHeight';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Google} from '~/common/svg/Google';
import {Apple} from '~/common/svg/Apple';
import {Outlook} from '~/common/svg/Outlook';
import {Link} from '~/common/_pb_components/atoms/Link';

export const AddToCalendar = ({
  isButton,
  preview: isPreview,
  title = 'Add to calendar',
  text = '',
  ratio,
  className = '',
  googleCalUrl = window.calendar_urls?.google_cal_url ?? '',
  iCalUrl,
  outlookUrl,
  type = 'event',
  buttonSize = 'large',
  'data-type': dataType = 'calendar',
  'data-qa-id': dataQaId,
  'data-source': dataSource = '',
  'data-action': dataAction = 'download',
}) => {
  const ref = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuTop, setMenuTop] = useState();
  const [scrollY, setScrollY] = useState(window.scrollY);
  const browserWidth = useBrowserWidth();
  const browserHeight = useBrowserHeight();
  const textToShow = text || title;

  const downloadCalendarEvent = () => {
    window.location.href = `${window.location.origin}/event/${window.event_id}/export/ical/?gid=${window.guest_id}`;
  };

  const createAddToCalendarModal = () => {
    const isMobile = utils.isMobile();

    return new EviteModal({
      width: '640px',
      url: urls.get('modal_add_to_calendar', {
        event_id: window.evite_event.id,
      }),
      url_parameters: {
        guest_id: window.guest_id,
        is_mobile: isMobile,
      },
    });
  };

  const handleSelectAppleOrOutlookCalendar = (event) => {
    event.preventDefault();

    const isMobile = utils.isMobile();
    if (isMobile) {
      downloadCalendarEvent();
    } else {
      const addToCalendarModal = createAddToCalendarModal();
      addToCalendarModal.show();
    }

    return false;
  };

  const handleClick = (event) => {
    event.preventDefault();

    setMenuOpen(true);
  };

  const watchCalendarMenuClick = (event) => {
    if (!menuOpen) return;
    if (document.querySelector('#add-to-calendar').contains(event.target)) return;
    const calendarOptionsMenu = document.getElementById('calendar-options-menu');
    if (!calendarOptionsMenu) return;

    if (event.target !== calendarOptionsMenu && !calendarOptionsMenu.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    let timeoutId = null;
    const watchScrollY = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setScrollY(window.scrollY), 150);
    };

    window.addEventListener('click', watchCalendarMenuClick);
    window.addEventListener('scroll', watchScrollY);
    window.addEventListener('resize', watchScrollY);
    return () => {
      window.removeEventListener('click', watchCalendarMenuClick);
      window.removeEventListener('scroll', watchScrollY);
      window.removeEventListener('scroll', watchScrollY);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (!ref.current) return;
    const offsetY = ref.current.offsetTop;
    const {top} = ref.current.getBoundingClientRect();
    if (top + 288 > window.innerHeight) {
      // 288 = twice the height of the calendar options menu
      setMenuTop(`calc(${offsetY}px - 9rem)`);
    } else {
      setMenuTop(`calc(${offsetY}px + 2rem)`);
    }
  }, [browserWidth, browserHeight, menuOpen, scrollY]);

  const renderGoogleCalendarButton = useCallback(
    () =>
      googleCalUrl ? (
        <Link
          as="transparent"
          variant="small"
          className="add-to-calendar__calendar-option"
          href={googleCalUrl}
          target="_blank"
          data-qa-id="add-to-cal__google"
        >
          <Google ratio={0.75} />
          Add to Google calendar
        </Link>
      ) : null,
    [googleCalUrl]
  );

  const renderAppleCalendarButton = useCallback(() => {
    if (iCalUrl) {
      return (
        <Link
          as="transparent"
          variant="small"
          className="add-to-calendar__calendar-option"
          href={iCalUrl}
          target="_blank"
          data-qa-id="add-to-cal__apple"
        >
          <Apple ratio={0.75} />
          Add to Apple calendar
        </Link>
      );
    }
    if (type === 'event') {
      return (
        <Button
          variant="transparent"
          className="add-to-calendar__calendar-option"
          onClick={handleSelectAppleOrOutlookCalendar}
          data-qa-id="add-to-cal__apple"
        >
          <Apple ratio={0.75} />
          Add to Apple calendar
        </Button>
      );
    }
    return null;
  }, [iCalUrl, type, handleSelectAppleOrOutlookCalendar]);

  const renderOutlookCalendarButton = useCallback(() => {
    if (outlookUrl) {
      return (
        <Link
          as="transparent"
          variant="small"
          className="add-to-calendar__calendar-option"
          href={outlookUrl}
          target="_blank"
          data-qa-id="add-to-cal__outlook"
        >
          <Outlook ratio={0.75} />
          Add to Outlook calendar
        </Link>
      );
    }
    if (type === 'event') {
      return (
        <Button
          variant="transparent"
          className="add-to-calendar__calendar-option"
          onClick={handleSelectAppleOrOutlookCalendar}
          data-qa-id="add-to-cal__outlook"
        >
          <Outlook ratio={0.75} />
          Add to Outlook calendar
        </Button>
      );
    }
    return null;
  }, [outlookUrl, type, handleSelectAppleOrOutlookCalendar]);

  const calendarLinksAvailable = type === 'event' || googleCalUrl || iCalUrl || outlookUrl;

  // Not clickable if its preview mode.
  return calendarLinksAvailable ? (
    <>
      <button
        id="add-to-calendar"
        data-qa-id={dataQaId ?? `add-to-calendar`}
        className={cx('unstyled-button', 'link', buttonSize, 'add-to-calendar-btn', {
          disabled: isPreview,
          [className]: !!className,
        })}
        title={title}
        data-type={dataType}
        data-source={dataSource}
        data-action={dataAction}
        onClick={handleClick}
        disabled={isPreview}
        ref={ref}
        type="button"
      >
        {isButton ? (
          `Add to calendar`
        ) : (
          <>
            <CalendarIcon ratio={ratio} color={isPreview ? pebble : tanzanite} />
            <Typography
              classNames="add-to-calendar-btn-text"
              color={isPreview ? 'pebble' : 'tanzanite'}
              variant="paragraph2"
            >
              {textToShow}
            </Typography>
          </>
        )}
      </button>
      {menuOpen && (
        <div
          id="calendar-options-menu"
          className="add-to-calendar__calendar-options-menu"
          style={{
            top: menuTop,
          }}
        >
          {renderGoogleCalendarButton()}
          {renderAppleCalendarButton()}
          {renderOutlookCalendarButton()}
        </div>
      )}
    </>
  ) : null;
};
