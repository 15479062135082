import {useCallback, useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Counter} from '~/common/_pb_components/atoms/Counter';
import {selectSignupsById} from '~/SignUpSheets/reducers/sheet/selectors';
import {getSpecificSignup} from '~/SignUpSheets/utils/misc';

export const VolunteerSignupOverlayCounter = ({
  selectedItemInSlot,
  selectedSignupsToShow,
  updateSelectedSignupsToShow,
  adjustQuantity,
  currentUserId,
}) => {
  const signups = useSelector(selectSignupsById(selectedItemInSlot), shallowEqual);

  const rowSignupsByOthers = useMemo(
    () => signups.filter((s) => s.user_id !== currentUserId),
    [signups, currentUserId]
  );

  const maxForThisItem = useMemo(() => {
    if (selectedItemInSlot.slot_id) {
      const slot = selectedItemInSlot.so.slots.find(
        (s) => s.slot_id === selectedItemInSlot.slot_id
      );
      if (!slot) return 1;
      return (slot.quantity || 1) - rowSignupsByOthers.length;
    }
    return selectedItemInSlot.so.quantity - rowSignupsByOthers.length;
  }, [selectedItemInSlot, rowSignupsByOthers]);

  const adjustQuantityCallback = useCallback(
    (count) => {
      const intCount = parseInt(count, 10);
      const signupsForThisOption = selectedSignupsToShow.find((s) =>
        getSpecificSignup(s, selectedItemInSlot)
      );

      if (intCount < signupsForThisOption.selected_quantity) {
        adjustQuantity(selectedItemInSlot, 'subtract', selectedSignupsToShow, intCount);
      } else {
        adjustQuantity(selectedItemInSlot, 'add', selectedSignupsToShow, intCount);
      }
    },
    [selectedSignupsToShow, selectedItemInSlot]
  );

  const handleAdjustQuantity = (type) => {
    adjustQuantity(selectedItemInSlot, type, selectedSignupsToShow);
    updateSelectedSignupsToShow(selectedSignupsToShow);
  };

  const counterCount = selectedItemInSlot.selected_quantity;
  const minusDisabled = !(counterCount > 1);
  const isPlusDisabled = counterCount >= maxForThisItem;
  const disableCounter =
    selectedItemInSlot.selected_quantity === 1 && minusDisabled && isPlusDisabled;

  return (
    <Counter
      as="dropdown"
      id="your-slots"
      title="Your slots"
      count={selectedItemInSlot.selected_quantity || 1}
      max={maxForThisItem}
      min={1}
      onPlus={() => handleAdjustQuantity('add')}
      onMinus={() => handleAdjustQuantity('subtract')}
      inputCallback={adjustQuantityCallback}
      data-qa-id="item-signup-count"
      className="sign-up-overlay__counter"
      labelSize="smaller"
      labelClassName="sign-up-overlay__counter-label"
      labelColor="shadow"
      disabled={disableCounter}
    />
  );
};
