/* eslint-disable max-lines-per-function */
import {useCallback, useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {pluralize} from '~/common/utils';
import {PB_Trash as TrashIcon} from '~/common/svg/PB_Trash';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Input} from '~/common/_pb_components/atoms/Input';
import {ResponsivePanel} from '~/common/_pb_components/organisms/ResponsivePanel';
import {Button} from '~/common/_pb_components/atoms/Button';
import {useSaveOrUpdateSignups} from '~/SignUpSheets/hooks/useSaveOrUpdateSignups';
import {useRemoveSignup} from '~/SignUpSheets/hooks/useRemoveSignup';
import {
  selectIsOrganizer,
  selectOrganizerName,
  selectSelectedSignups,
  selectSignupOptions,
  selectSignups,
  selectSignupsPendingDeletion,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {
  SET_SELECTED_SIGNUPS,
  SET_SIGNUPS_PENDING_DELETION,
  SET_SIGNUPS,
} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectIsDateTimeBlueprint,
  selectSignupCommentMaxLength,
} from '~/SignUpSheets/reducers/blueprint/selectors';
import {getSpecificSignup, isAllDay, resizeTextarea} from '~/SignUpSheets/utils/misc';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';
import {VolunteerLinkPreview} from '~/SignUpSheets/components/LinkPreview/VolunteerLinkPreview';
import {FilledSlots} from '~/SignUpSheets/components/FilledSlots/FilledSlots';
import {VolunteerSignupOverlayCounter} from './VolunteerSignupOverlayCounter';

import {pebble} from '~sass/pb_styleguide/base/_exports.sass';
import './SignUpOverlay.sass';

export const SignUpOverlay = ({
  selectedSignups,
  closeOverlay,
  isOpen,
  isEditing,
  legacyDateTime,
  isDesktop,
  currentUserId,
  signedInId,
  newUserId,
}) => (
  <ResponsivePanel
    isOpen={isOpen}
    onClose={closeOverlay}
    title={`${isEditing ? 'Edit' : 'Confirm'} ${pluralize('signup', selectedSignups.length)}`}
    modalId="volunteer-signup"
    closeQaId="volunteer-signup"
    showCover
    portalMode
  >
    <div className="one-step-flow__overlay-body">
      <OverlayContent
        closeOverlay={closeOverlay}
        isEditing={isEditing}
        isOpen={isOpen}
        legacyDateTime={legacyDateTime}
        isDesktop={isDesktop}
        currentUserId={currentUserId}
        signedInId={signedInId}
        newUserId={newUserId}
      />
    </div>
  </ResponsivePanel>
);

const OverlayContent = ({
  closeOverlay,
  isEditing,
  isOpen,
  legacyDateTime,
  isDesktop,
  currentUserId,
  signedInId,
  newUserId,
}) => {
  const dispatch = useDispatch();
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [signupError, setSignupError] = useState({});
  const [volunteerName, setVolunteerName] = useState('');
  const [volunteerEmail, setVolunteerEmail] = useState('');
  const [selectedSignupsToShow, setSelectedSignupsToShow] = useState([]);
  const signupOptions = useSelector(selectSignupOptions, shallowEqual);
  const selectedSignups = useSelector(selectSelectedSignups, shallowEqual);
  const signupsPendingDeletion = useSelector(selectSignupsPendingDeletion, shallowEqual);
  const {linkPreviews} = useAffiliateActions();
  const signups = useSelector(selectSignups, shallowEqual);
  const isOrganizer = useSelector(selectIsOrganizer);
  const organizerName = useSelector(selectOrganizerName);

  const {onSave} = useSaveOrUpdateSignups({
    volunteerName,
    volunteerEmail,
    isEditing,
    setNameError,
    setEmailError,
    setSignupError,
  });
  const [handleRemoveSignup] = useRemoveSignup(closeOverlay);
  const signedInEmail = useSelector((state) => state.user.email);
  const userName = useSelector((state) => state.user.userName);
  const signedInName = signups.find((s) => s.email === signedInEmail)?.name || userName;

  const dispatchSelectedSignups = useCallback(
    (signupsToSubmit) => {
      dispatch({
        type: SET_SELECTED_SIGNUPS,
        payload: signupsToSubmit,
      });
    },
    [dispatch]
  );

  const dispatchSignupsPendingDeletion = useCallback(
    (signupsToDelete) => {
      dispatch({
        type: SET_SIGNUPS_PENDING_DELETION,
        payload: signupsToDelete,
      });
    },
    [dispatch]
  );

  const handleCommentChange = useCallback(
    ({item, comment}) => {
      const selected = [...selectedSignups];
      const idx = selected.findIndex((s) => getSpecificSignup(s, item));
      if (idx >= 0) {
        selected.splice(idx, 1, {...selected[idx], comment});
      }
      dispatch({
        type: SET_SELECTED_SIGNUPS,
        payload: selected,
      });
    },
    [dispatch, selectedSignups]
  );

  const handleTrashCanClick = useCallback(
    (item) => {
      const isFromOtherSignupOrOtherUser = (f) =>
        // Hold onto others' signups, as well as your signups for different slots, in the UI
        (f.slot_id ? f.slot_id !== item.slot_id : f.signup_option !== item.signup_option) ||
        (f.user_id !== '' && f.user_id !== currentUserId);

      const signupsToRemove = signups.filter((f) => !isFromOtherSignupOrOtherUser(f));
      signupsToRemove.forEach((signup) => {
        if (!signup.id) return; // Not a saved signup yet; nothing to remove on BE
        handleRemoveSignup(signup);
      });

      const signupsToKeep = signups.filter((f) => isFromOtherSignupOrOtherUser(f));
      dispatch({type: SET_SIGNUPS, payload: signupsToKeep});

      const selectedSignupsToKeep = selectedSignups.filter((f) => isFromOtherSignupOrOtherUser(f));
      dispatchSelectedSignups(selectedSignupsToKeep);

      const signupsPendingDeletionToKeep = signupsPendingDeletion.filter((f) =>
        isFromOtherSignupOrOtherUser(f)
      );
      dispatchSignupsPendingDeletion(signupsPendingDeletionToKeep);

      if (selectedSignupsToKeep.length === 0) {
        closeOverlay();
      }
    },
    [
      handleRemoveSignup,
      dispatch,
      closeOverlay,
      selectedSignups,
      signupsPendingDeletion,
      isEditing,
      currentUserId,
    ]
  );

  useEffect(() => {
    setVolunteerName(isEditing || signedInName ? signedInName : '');
    setVolunteerEmail(isEditing || signedInEmail ? signedInEmail : '');
  }, [isOpen, isEditing, signedInName, signedInEmail]);

  const addSignups = useCallback(
    (selectedItem, count) => {
      const selectedSignupsForSlot = selectedSignups.filter((s) =>
        getSpecificSignup(s, selectedItem)
      );
      let numToAdd = count - selectedSignupsForSlot.length;
      const slotsPendingDeletion = [...signupsPendingDeletion];
      const selected = [...selectedSignups];
      while (numToAdd > 0) {
        const idxOfSlotToRestore = slotsPendingDeletion.findIndex((s) =>
          getSpecificSignup(s, selectedItem)
        );
        const slotInsertIdx = selected.findIndex((s) => getSpecificSignup(s, selectedItem));
        if (idxOfSlotToRestore >= 0) {
          // There is an existing slot we can restore
          const slotToRestore = slotsPendingDeletion.splice(idxOfSlotToRestore, 1);
          selected.splice(Math.max(slotInsertIdx, 0), 0, ...slotToRestore); // Math.max accounts for possibly negative insertIdx
        } else {
          // This is a completely new slot
          selected.splice(Math.max(slotInsertIdx, 0), 0, {
            // Math.max accounts for possibly negative insertIdx
            ...selectedItem,
            id: undefined,
          });
        }
        numToAdd--;
      }
      dispatchSelectedSignups(selected);
      dispatchSignupsPendingDeletion(slotsPendingDeletion);
    },
    [
      signupsPendingDeletion,
      selectedSignups,
      dispatchSelectedSignups,
      dispatchSignupsPendingDeletion,
    ]
  );

  const removeSignups = useCallback(
    (selectedItem, count) => {
      const selectedSignupsForSlot = selectedSignups.filter((s) =>
        getSpecificSignup(s, selectedItem)
      );
      let numToRemove = selectedSignupsForSlot.length - count;
      if (count === 0) {
        handleTrashCanClick(selectedSignupsForSlot);
        return;
      }

      const slotsToDelete = [...signupsPendingDeletion];
      const selected = [
        ...selectedSignups.sort((a, b) => {
          if (!getSpecificSignup(a, b)) return 0; // Don't sort across signup options, just within
          if (!a.id && b.id) return -1;
          if (!b.id && a.id) return 1;
          if (a.id && b.id) return b.id - a.id;
          return 0;
        }),
      ];
      while (numToRemove > 0) {
        let idx = selected.findIndex((s) => getSpecificSignup(s, selectedItem) && !s.comment);
        if (idx < 0) idx = selected.findIndex((s) => getSpecificSignup(s, selectedItem));
        if (idx >= 0) {
          const existingSlotToDelete = selected.splice(idx, 1);
          if (existingSlotToDelete[0].id) {
            // Previously saved signup; stage for deletion but don't remove until user has clicked Save
            // Push to front of list
            slotsToDelete.splice(0, 0, existingSlotToDelete[0]);
          }
        }
        numToRemove--;
      }
      dispatchSelectedSignups(selected);
      dispatchSignupsPendingDeletion(slotsToDelete);
    },
    [
      selectedSignups,
      signupsPendingDeletion,
      dispatchSelectedSignups,
      dispatchSignupsPendingDeletion,
    ]
  );

  const adjustQuantity = useCallback(
    (signupOption, type, selectedToAdjust, count) => {
      const selectedItem = selectedToAdjust.find((s) => getSpecificSignup(s, signupOption));

      if (selectedItem) {
        switch (type) {
          case 'add': {
            addSignups(selectedItem, count);
            break;
          }
          case 'subtract': {
            removeSignups(selectedItem, count);
            break;
          }
          default: {
            break;
          }
        }
      }
      return selectedToAdjust;
    },
    [addSignups, removeSignups]
  );

  const updateSelectedSignupsToShow = useCallback(
    (allSelectedSignups) => {
      const mapGroups = {};

      const groupedSignups = allSelectedSignups.reduce((acc, s) => {
        const existingSignup = acc.find((item) => getSpecificSignup(item, s));

        if (existingSignup) {
          existingSignup.selected_quantity++;
        } else {
          acc.push({...s, selected_quantity: 1});
        }
        return acc;
      }, []);

      const formattedSignupsToShow = groupedSignups.map((signup, index) => {
        const so = signupOptions.find((s) => s.uuid === signup.signup_option);
        if (so.uuid in mapGroups) {
          mapGroups[so.uuid] = Math.min(index, mapGroups[so.uuid]);
        } else {
          mapGroups[so.uuid] = index;
        }
        return {
          ...signup,
          so,
          index: mapGroups[so.uuid],
        };
      });

      setSelectedSignupsToShow(
        formattedSignupsToShow.toSorted((a, b) => {
          if (a.start_time) {
            return (
              new Date(`${a.so?.date} ${a.start_time}`) - new Date(`${b.so?.date} ${b.start_time}`)
            );
          }
          if (a.so?.date) {
            return new Date(a.so?.date) - new Date(b.so?.date);
          }
          if (a.signup_option === b.signup_option) {
            return a.id - b.id;
          }
          return a.index - b.index;
        })
      );
    },
    [signupOptions]
  );

  useEffect(() => {
    updateSelectedSignupsToShow(selectedSignups);
  }, [selectedSignups, updateSelectedSignupsToShow]);

  return (
    <div className="sign-up-overlay__content">
      <Input
        className="sign-up-overlay__your-name"
        data-qa-id="sign-up-overlay-your-name"
        type="text"
        label="Your Name"
        required
        value={volunteerName}
        disabled={isEditing}
        onChange={(e) => setVolunteerName(e.target.value)}
        placeholder="Enter your name"
        error={nameError}
      />
      <Input
        className="sign-up-overlay__your-email"
        data-qa-id="sign-up-overlay-your-email"
        type="text"
        label="Your Email Address"
        required
        value={volunteerEmail}
        disabled={isEditing}
        onChange={(e) => setVolunteerEmail(e.target.value)}
        placeholder="Enter your email address"
        error={emailError}
      />
      {selectedSignups.length > 0 && (
        <div className="signup-up-overlay__sign-up-items">
          {selectedSignupsToShow.map((selected) => {
            const productLink = selected.slot_id
              ? {
                  ...(selected.so.slots.find((slot) => slot.slot_id === selected.slot_id)
                    ?.product_link ?? {}),
                  ...linkPreviews[selected.slot_id],
                }
              : {
                  ...(selected.so.product_link ?? {}),
                  ...linkPreviews[selected.signup_option],
                };
            return (
              <SignUpItemForm
                key={`${selected.signup_option}__${selected.slot_id}`}
                selected={{
                  ...selected,
                  productLink,
                }}
                signupError={signupError}
                legacyDateTime={legacyDateTime}
                selectedSignups={selectedSignups}
                selectedSignupsToShow={selectedSignupsToShow}
                updateSelectedSignupsToShow={updateSelectedSignupsToShow}
                adjustQuantity={adjustQuantity}
                handleCommentChange={handleCommentChange}
                handleTrashCanClick={handleTrashCanClick}
                isEditing={isEditing}
                isOrganizer={isOrganizer}
                organizerName={organizerName}
                isDesktop={isDesktop}
                currentUserId={currentUserId}
                signedInId={signedInId}
                newUserId={newUserId}
              />
            );
          })}
        </div>
      )}
      <Button
        className="sign-up-overlay__save-btn"
        data-qa-id="sign-up-overlay-save-btn"
        variant="primary"
        onClick={() => onSave()}
        fullWidth
        size="large"
      >
        Save
      </Button>
    </div>
  );
};

const SignUpItemForm = ({
  selected,
  signupError,
  legacyDateTime,
  selectedSignups,
  selectedSignupsToShow,
  updateSelectedSignupsToShow,
  adjustQuantity,
  handleCommentChange,
  handleTrashCanClick,
  isDesktop,
  isOrganizer,
  organizerName,
  currentUserId,
  signedInId,
  newUserId,
}) => {
  const {signup_option: signupOption, comment, productLink, slot_id: slotId} = selected;
  const item = selected.so;
  const slot = item?.slots?.find((s) => selected.slot_id === s.slot_id);
  const slotTitle = slot?.title || item?.item;
  const slotDescription = slot?.description;
  const allDay = isAllDay(selected.start_time, selected.end_time);
  const commentRef = useRef(null);
  const signupCommentMaxLength = useSelector(selectSignupCommentMaxLength);
  const signups = useSelector(selectSignups);
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);

  let signUpsForThis = [];
  if (isDatetimeType) {
    signUpsForThis = signups.filter((signUp) => signUp?.slot_id === slot?.slot_id);
  } else {
    signUpsForThis = signups.filter((signUp) => signUp?.signup_option === item?.uuid);
  }

  let errorMsg = '';
  if (slotId) {
    errorMsg = signupError.slot_id === slotId ? signupError.message : '';
  } else {
    errorMsg = signupError.signup_option === signupOption ? signupError.message : '';
  }

  useEffect(() => {
    resizeTextarea(commentRef.current);
  }, []);

  return (
    <>
      <div className="sign-up-overlay__content-row">
        <div
          className={cx('sign-up-overlay__item', {
            'sign-up-overlay__item--error': errorMsg,
          })}
        >
          <div className="sign-up-overlay__item-row">
            <div>
              {!allDay ? (
                <div className="sign-up-overlay__item-date-time">
                  <Typography variant="header5" data-qa-id="item-date">
                    {slotTitle || `${selected.start_time} - ${selected.end_time}`}
                  </Typography>
                  {selected.start_time && selected.end_time && slotTitle ? (
                    <Typography variant="list1" size="large" data-qa-id="item-time-range">
                      {selected.start_time} - {selected.end_time}
                    </Typography>
                  ) : null}
                </div>
              ) : (
                slotTitle && (
                  <Typography
                    variant="header5"
                    className="sign-up-overlay__item-name"
                    data-qa-id="item-name"
                  >
                    {slotTitle}
                  </Typography>
                )
              )}
              {!legacyDateTime && slotDescription && (
                <Typography variant="paragraph3" data-qa-id="item-description" color="pebble">
                  {slotDescription}
                </Typography>
              )}
            </div>
            {!legacyDateTime && (
              <VolunteerSignupOverlayCounter
                selectedItemInSlot={selected}
                selectedSignups={selectedSignups}
                selectedSignupsToShow={selectedSignupsToShow}
                updateSelectedSignupsToShow={updateSelectedSignupsToShow}
                adjustQuantity={adjustQuantity}
                currentUserId={currentUserId}
              />
            )}
          </div>
          <VolunteerLinkPreview
            uuid={slotId ?? signupOption}
            productLink={productLink}
            isOrganizer={isOrganizer}
            organizerName={organizerName}
            isDesktop={isDesktop}
            disabled
            compact
            showDisclaimerPopover={false}
          />
          <Input
            inputClassName="signup-sheets__text-textarea text__textarea"
            data-qa-id="sign-up-overlay-item-comment"
            type="textarea"
            value={comment || ''}
            onChange={(e) =>
              handleCommentChange({
                item: selected,
                comment: e.target.value,
              })
            }
            placeholder="Add a comment (optional)"
            showOptionalLabel
            rows={1}
            maxLength={signupCommentMaxLength}
            ref={commentRef}
            onInput={() => resizeTextarea(commentRef.current)}
          />

          <FilledSlots slots={signUpsForThis} signedInId={signedInId} newUserId={newUserId} />
        </div>
        <Button
          variant="transparent"
          className="sign-up-overlay__item-remove-btn"
          onClick={() => handleTrashCanClick(selected)}
          data-qa-id="sign-up-overlay-item-remove-btn"
        >
          <TrashIcon ratio={1.5} color={pebble} />
        </Button>
      </div>
      {errorMsg && (
        <Typography color="mexicanOpalWebText" variant="label3">
          {errorMsg}
        </Typography>
      )}
    </>
  );
};
