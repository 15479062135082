import React, {createContext, useState} from 'react';

const defaultContextValue = {
  setCanvas: () => null,
  canvas: null,
  activeObjects: [],
};

export const FabricContext = createContext(defaultContextValue);

export const FabricContextParent = ({children}) => {
  const [canvas, setCanvas] = useState();
  const [activeObjects, setActiveObjects] = useState();

  return (
    <FabricContext.Provider
      value={{
        canvas,
        setCanvas,
        activeObjects,
        setActiveObjects,
      }}
    >
      {children}
    </FabricContext.Provider>
  );
};
