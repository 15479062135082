/* eslint-disable max-lines-per-function */
import moment from 'moment-timezone';
import cx from 'classnames';
import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {PB_Chevron as Chevron} from '~/common/svg/PB_Chevron';
import {PB_EditLine as EditIcon} from '~/common/svg/PB_EditLine';
import {PB_CheckmarkCircle as CheckmarkCircle} from '~/common/svg/PB_CheckmarkCircle';
import {Accordion} from '~/common/_pb_components/molecules/Accordion';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Button} from '~/common/_pb_components/atoms/Button';
import {useIsLargishAndUp, useMatchQuery} from '~/common/hooks/useMatchQuery';
import {useRequestLogin} from '~/common/fabric/Card/components/LoginModal/hooks/useRequestLogin';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {LocationIcon} from '~/common/svg/LocationIconAlt';
import {
  formatDateLong,
  formatDateShort,
  isAllDay,
  signupOptionIsBeforeToday,
  signupOptionIsToday,
  slotElapsed,
} from '~/SignUpSheets/utils/misc';
import {
  selectExpired,
  selectIsOrganizer,
  selectIsPreviewMode,
  selectOrganizerName,
  selectSelectedSignups,
  selectSignupsByUuid,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {SectionRow} from '~/SignUpSheets/components/PageSection/PageSection';
import {selectIsDateTimeBlueprint} from '~/SignUpSheets/reducers/blueprint/selectors';
import {SET_SELECTED_SIGNUPS} from '~/SignUpSheets/reducers/sheet/constants';
import {sortDownPastTimes, useSortTimes} from '~/SignUpSheets/hooks/useSortTimes';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';
import {VolunteerLinkPreview} from '~/SignUpSheets/components/LinkPreview/VolunteerLinkPreview';
import {selectSlotColor, selectSlotLine} from '~/SignUpSheets/reducers/themes/selectors';
import {PurchasedController} from './PurchasedController';
import {useSaveOrUpdateSignups} from '~/SignUpSheets/hooks/useSaveOrUpdateSignups';
import {useFormattedAddress} from '~/SignUpSheets/components/Details/hooks/useFormattedAddress';
import {SignUpButton} from './SignUpButton';
import {SplitViewLeftColumn} from './SplitViewLeftColumn';

import {
  charcoal,
  emerald,
  pebble,
  fog,
  mediumAndUpQuery,
} from '~sass/pb_styleguide/base/_exports.sass';
import './VolunteerSignUpRow.sass';

const isSlotSelected = (slot, obj) =>
  obj.slot_id === slot.slot_id && obj.signup_option === slot.signup_option;

export const VolunteerSignUpRow = ({
  rowData,
  openSignupOverlay,
  setIsEditing,
  allExpired,
  canceled,
  split,
  hideForLegacyDateTime,
  signedInId,
  newUserId,
}) => {
  const {uuid = '0', quantity = 0, slots = []} = rowData;
  const [pendingSlot, setPendingSlot] = useState(null);
  const signedInName = useSelector((state) => state.user.userName);
  const signedInEmail = useSelector((state) => state.user.email);
  const {onSave} = useSaveOrUpdateSignups({
    volunteerEmail: signedInEmail,
    volunteerName: signedInName,
    isEditing: true,
    scope: 'view',
  });
  const selectedSignups = useSelector(selectSelectedSignups, shallowEqual);
  const signups = useSelector(selectSignupsByUuid(uuid), shallowEqual);
  const isOrganizer = useSelector(selectIsOrganizer);
  const organizerName = useSelector(selectOrganizerName);
  const past = useSelector((s) => selectExpired(s, {uuid}));
  const startDate = useSelector(
    (s) => s.sheet.signup_options.find(({uuid: a}) => a === uuid),
    shallowEqual
  )?.date;
  const isToday = moment(startDate).isSame(moment(), 'day');
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const dispatch = useDispatch();
  const {requestLogin} = useRequestLogin();
  const {linkPreviews} = useAffiliateActions();
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const isLargishAndUp = useIsLargishAndUp();
  const cityStateZip = useFormattedAddress();

  const rowSignups = useMemo(() => {
    if (isDatetimeType) return signups;

    return signups.sort((a, b) => {
      if (a.name && !b.name) return -1;
      if (b.name && !a.name) return 1;
      return a.id - b.id;
    });
  }, [signups, uuid]);

  const toggleSelected = useCallback(
    (slot) => {
      const isSelected = selectedSignups.some((obj) => isSlotSelected(slot, obj));
      const selectedArr = isSelected
        ? selectedSignups.filter((s) => s.signup_option !== slot.signup_option)
        : [...selectedSignups, {...slot}];

      dispatch({
        type: SET_SELECTED_SIGNUPS,
        payload: selectedArr,
      });
    },
    [selectedSignups, isSlotSelected, dispatch]
  );

  const openOverlayWithSlot = (slot) => {
    const isSelected = selectedSignups.some((obj) => isSlotSelected(slot, obj));
    if (!isSelected) {
      const selectedArr = [...selectedSignups, ...slot.signups];
      dispatch({
        type: SET_SELECTED_SIGNUPS,
        payload: selectedArr,
      });
    }
    openSignupOverlay();
    setIsEditing(true);
  };

  const handleEditClick = useCallback(
    async (slot) => {
      if (!(await requestLogin())) return;

      if (signedInId === slot.user_id) {
        openOverlayWithSlot(slot);
      } else {
        setPendingSlot(slot);
      }
    },
    [signedInId, requestLogin, openOverlayWithSlot, setPendingSlot]
  );

  const onDeclarePurchased = useCallback(
    (signupsToMarkPurchased = []) => {
      if (signupsToMarkPurchased.length === 0) return;
      const updatedSignups = signupsToMarkPurchased.map((s) => ({
        ...s,
        purchased: true,
      }));
      onSave(updatedSignups);
    },
    [onSave]
  );

  const onCancelPurchased = useCallback(
    (signupsToMarkNotPurchased = []) => {
      if (signupsToMarkNotPurchased.length === 0) return;
      const updatedSignups = signupsToMarkNotPurchased.map((s) => ({
        ...s,
        purchased: false,
      }));
      onSave(updatedSignups);
    },
    [onSave]
  );

  useEffect(() => {
    if (pendingSlot && signedInId === pendingSlot.user_id) {
      openOverlayWithSlot(pendingSlot);
      setPendingSlot(null);
    }
  }, [pendingSlot, signedInId]);
  const expired = useMemo(() => past || canceled, [past, canceled]);

  const {matches: isMedium} = useMatchQuery(`(${mediumAndUpQuery})`);
  const [dateExpanded, setDateExpanded] = useState(true);

  const SlotsView = useMemo(() => {
    const selectedForPendingSignup = selectedSignups.find((obj) => obj.signup_option === uuid);
    const hasOpenSlots = rowSignups.length < (quantity || slots.length) && !expired;

    const row = {
      ...rowData,
      slots: rowData.slots?.map((slot) => ({
        ...slot,
        product_link: {
          ...(slot.product_link ?? {}),
          ...linkPreviews[slot.slot_id],
        },
      })),
      product_link: {
        ...(rowData.product_link ?? {}),
        ...linkPreviews[rowData.uuid],
      },
    };

    return (
      <Slots
        split={split}
        hideForLegacyDateTime={hideForLegacyDateTime}
        selectedForPendingSignup={selectedForPendingSignup}
        expired={expired}
        organizerName={organizerName}
        row={row}
        startDate={startDate}
        past={past}
        allExpired={allExpired}
        isToday={isToday}
        toggleSelected={toggleSelected}
        hasOpenSlots={hasOpenSlots}
        rowSignups={rowSignups}
        selected={selectedSignups}
        signedInId={signedInId}
        newUserId={newUserId}
        isOrganizer={isOrganizer}
        handleEditClick={handleEditClick}
        onDeclarePurchased={onDeclarePurchased}
        onCancelPurchased={onCancelPurchased}
      />
    );
  }, [
    split,
    hideForLegacyDateTime,
    expired,
    rowData,
    isToday,
    startDate,
    past,
    allExpired,
    toggleSelected,
    rowSignups,
    selectedSignups,
    signedInId,
    newUserId,
    isOrganizer,
    handleEditClick,
    organizerName,
    isLargishAndUp,
    onDeclarePurchased,
    onCancelPurchased,
  ]);

  if (split && !isMedium)
    return (
      <Accordion
        uniqueAccordionBodyWrapperId={uuid}
        className={cx('split-page-section__accordion', {preview: isPreviewMode})}
        label={formatDateLong(rowData.date) || '[Date]'}
        labelVariant={isLargishAndUp ? 'header5' : 'paragraph1'}
        labelClassName="split-page-section__slot__date large semibold"
        subLabel={rowData.title}
        subLabelVariant="list2"
        labelDescription={rowData.description}
        labelDecoration={
          <>
            {past && !allExpired && <Pill expired>past</Pill>}
            {isToday && !past && <Pill>today</Pill>}
          </>
        }
        labelDescriptionVariant="paragraph3"
        additionalLabelContent={
          (cityStateZip || isPreviewMode) && (
            <div className="split-page-section__slot__location">
              <LocationIcon ratio={0.5} className="split-page-section__slot__location__icon" />
              <Typography variant="paragraph3" color="pebble">
                {cityStateZip || '[Location]'}
              </Typography>
            </div>
          )
        }
        chevron={<Chevron direction={dateExpanded ? 'up' : 'down'} ratio={0.75} />}
        onClose={() => setDateExpanded(false)}
        onOpen={() => setDateExpanded(true)}
      >
        {SlotsView}
      </Accordion>
    );

  return (
    <SectionRow
      split={split}
      expired={expired}
      sectionQaId={`signup-options-row-${rowData.position_index}`}
    >
      {split && (
        <SplitViewLeftColumn
          dateSlot={rowData}
          isToday={isToday}
          past={past}
          allExpired={allExpired}
        />
      )}
      {split ? <div className="split-page-section__slot__times">{SlotsView}</div> : SlotsView}
    </SectionRow>
  );
};

const Slots = ({
  split = false,
  hideForLegacyDateTime,
  expired = false,
  startDate,
  organizerName,
  row: {
    uuid,
    quantity,
    date: rowDate,
    item,
    slots: unsortedSlots = [],
    description,
    product_link: productLink,
  },
  past = false,
  allExpired = false,
  isToday,
  toggleSelected,
  selectedForPendingSignup,
  hasOpenSlots,
  rowSignups = [],
  selected: selectedSignups = [],
  signedInId,
  newUserId,
  isOrganizer,
  handleEditClick,
  onDeclarePurchased,
  onCancelPurchased,
}) => {
  const dispatch = useDispatch();
  const {matches: isMedium} = useMatchQuery(`(${mediumAndUpQuery})`);
  const isLargishAndUp = useIsLargishAndUp();
  const slotColor = useSelector(selectSlotColor);
  const slotLine = useSelector(selectSlotLine);

  const {list: slots} = useSortTimes(unsortedSlots, (a, b) => sortDownPastTimes(a, b, isToday));

  if (split) {
    return slots.map((slot, i) => {
      const descriptionToShow = slot.description;
      const quantityToShow = slot.quantity || quantity;
      const allDay = isAllDay(slot.start_time, slot.end_time);
      const slotProductLink = slot.product_link;
      const filledForThisSlot = useMemo(
        () =>
          rowSignups
            .filter((f) => f.slot_id === slot.slot_id)
            .map((s) => ({...s, start_time: slot.start_time, end_time: slot.end_time})),
        [rowSignups]
      );
      const isLastItem = i === slots.length - 1;

      const selectedForPendingTimeSignup = selectedSignups.find(
        (obj) => obj.slot_id === slot.slot_id
      );

      const hasOpenSlotsForThisTime = hideForLegacyDateTime
        ? filledForThisSlot.length < 1
        : filledForThisSlot.length < (quantityToShow || slots.length) && !expired;

      const groupedSignups = useMemo(() => {
        const groups = filledForThisSlot.reduce((acc, s) => {
          if (!s.user_id) return acc;
          if (s.user_id in acc) {
            acc[s.user_id].signups.push(s);
            if (!acc[s.user_id].comment && s.comment) {
              acc[s.user_id].comment = s.comment;
            }
          } else {
            acc[s.user_id] = {
              ...s,
              signups: [s],
            };
          }
          return acc;
        }, {});
        return groups;
      }, [filledForThisSlot]);

      const yourSignups = useMemo(
        () => groupedSignups[signedInId || newUserId],
        [groupedSignups, signedInId, newUserId]
      );

      const toggleSelectedTimeSlot = useCallback(
        (timeSlot) => {
          const isSelected = selectedSignups.some((obj) => isSlotSelected(timeSlot, obj));
          const selectedArr = isSelected
            ? selectedSignups.filter((s) => s.slot_id !== timeSlot.slot_id)
            : [...selectedSignups, {...timeSlot}];

          dispatch({
            type: SET_SELECTED_SIGNUPS,
            payload: selectedArr,
          });
        },
        [selectedSignups, isSlotSelected, dispatch]
      );

      const PurchasedControllerView = useMemo(
        () =>
          yourSignups ? (
            <PurchasedController
              purchased={yourSignups.purchased}
              onDeclarePurchased={() => onDeclarePurchased(yourSignups.signups)}
              onCancel={() => onCancelPurchased(yourSignups.signups)}
              uuid={slot.slot_id}
            />
          ) : null,
        [yourSignups, onDeclarePurchased, onCancelPurchased, slot.slot_id]
      );

      const timeSlotIsPast = useMemo(
        () =>
          signupOptionIsBeforeToday({date: rowDate}) ||
          (signupOptionIsToday({date: rowDate}) && !allDay && slotElapsed(slot)),
        [rowDate, slot]
      );

      const SlotActions = useMemo(() => {
        const slotSkeleton = {
          signup_option: uuid,
          user_id: '',
          name: '',
          email: '',
          comment: '',
          slot_id: slot.slot_id,
          start_time: slot.start_time,
          end_time: slot.end_time,
        };
        if (expired) return null;
        if (yourSignups) {
          return (
            <div className="volunteer-signup-row__signup-actions">
              {slotProductLink?.url && slotProductLink?.vendor_name && !yourSignups.purchased ? (
                <>
                  <Button
                    variant="secondary"
                    size="small"
                    icon={<EditIcon color={charcoal} ratio={0.5} />}
                    onClick={() => handleEditClick(yourSignups)}
                    data-qa-id="edit-signup"
                    aria-label="Edit signup"
                  />
                  <Link
                    as="primary"
                    href={slotProductLink.affiliate_url}
                    variant="small"
                    data-qa-id="purchase"
                    target="_blank"
                  >
                    Purchase
                  </Link>
                </>
              ) : (
                <Button
                  variant="secondary"
                  size="small"
                  data-qa-id="edit-signup"
                  onClick={() => handleEditClick(yourSignups)}
                >
                  Edit signup
                </Button>
              )}
            </div>
          );
        }
        if (hasOpenSlotsForThisTime && !timeSlotIsPast) {
          return (
            <SignUpButton
              toggleSelected={toggleSelectedTimeSlot}
              slot={slotSkeleton}
              isSelected={selectedForPendingTimeSignup}
              expired={expired}
            />
          );
        }
        if (timeSlotIsPast || !hasOpenSlotsForThisTime) {
          return (
            <Typography
              variant="paragraph2"
              semibold
              color="coin"
              className="volunteer-signup-row__available__filled"
              data-qa-id="signup-filled"
              data-qa-value={rowSignups.length}
            >
              {timeSlotIsPast ? 'Signup Passed' : 'All slots filled'}
            </Typography>
          );
        }
        return null;
      }, [
        yourSignups,
        slotProductLink,
        rowSignups,
        expired,
        hasOpenSlotsForThisTime,
        toggleSelectedTimeSlot,
        selectedForPendingTimeSignup,
      ]);

      return (
        <Fragment key={slot.slot_id}>
          <div
            className={cx('signup-sheet__volunteer-signup-row', {
              expired,
              'signup-sheet__volunteer-signup-row--full': !hasOpenSlotsForThisTime,
            })}
            style={{
              backgroundColor: !hasOpenSlotsForThisTime && !yourSignups ? fog : slotColor,
              borderLeftColor: !hasOpenSlotsForThisTime && !yourSignups ? pebble : slotLine,
            }}
            data-qa-id="signup-count"
            data-qa-value={quantityToShow || slots.length}
          >
            <div className="volunteer-signup-row__header">
              <div
                className={cx('volunteer-signup-row__title', {
                  past: timeSlotIsPast,
                })}
              >
                <SlotHeading
                  split={split}
                  slot={slot}
                  item={item}
                  isToday={isToday}
                  signedUp={yourSignups}
                />
                <div className="volunteer-signup-row__slot-actions__wrapper">
                  {SlotActions}
                  {!(split && hideForLegacyDateTime) &&
                    quantityToShow &&
                    !timeSlotIsPast &&
                    hasOpenSlotsForThisTime && (
                      <Typography
                        variant={isLargishAndUp ? 'paragraph3' : 'paragraph4'}
                        className="volunteer-signup-row__available__filled"
                        color={hasOpenSlotsForThisTime ? 'emeraldWebText' : 'coin'}
                        semibold
                        data-qa-id="signup-filled"
                        data-qa-value={filledForThisSlot.length}
                      >
                        {filledForThisSlot.length} of {quantityToShow || slots.length} filled
                      </Typography>
                    )}
                </div>
              </div>
              <div className="volunteer-signup-row__available">
                {!(split && hideForLegacyDateTime) && descriptionToShow && (
                  <Typography variant="paragraph3" color="pebble" data-qa-id="signup-description">
                    {descriptionToShow}
                  </Typography>
                )}
              </div>
            </div>
            {!expired && (
              <VolunteerLinkPreview
                uuid={slot.slot_id}
                productLink={slotProductLink}
                isOrganizer={isOrganizer}
                organizerName={organizerName}
                disabled={!isOrganizer && !yourSignups}
                endAdornment={PurchasedControllerView}
              />
            )}
          </div>
          {!isMedium && !isLastItem && (
            <span className="signup-sheet__volunteer__accordion-spacer" />
          )}
        </Fragment>
      );
    });
  }

  const groupedSignups = useMemo(() => {
    const groups = rowSignups.reduce((acc, slot) => {
      if (!slot.user_id) return acc;
      if (slot.user_id in acc) {
        acc[slot.user_id].signups.push(slot);
        if (!acc[slot.user_id].comment && slot.comment) {
          acc[slot.user_id].comment = slot.comment;
        }
      } else {
        acc[slot.user_id] = {
          ...slot,
          signups: [slot],
        };
      }
      return acc;
    }, {});
    return groups;
  }, [rowSignups]);

  const yourSignups = useMemo(
    () => groupedSignups[signedInId || newUserId],
    [groupedSignups, signedInId, newUserId]
  );

  const PurchasedControllerView = useMemo(
    () =>
      yourSignups ? (
        <PurchasedController
          purchased={yourSignups.purchased}
          onDeclarePurchased={() => onDeclarePurchased(yourSignups.signups)}
          onCancel={() => onCancelPurchased(yourSignups.signups)}
          uuid={uuid}
        />
      ) : null,
    [yourSignups, onDeclarePurchased, onCancelPurchased, uuid]
  );

  const SlotActions = useMemo(() => {
    if (expired) return null;
    if (yourSignups) {
      return (
        <div className="volunteer-signup-row__signup-actions">
          {productLink?.url && productLink?.vendor_name && !yourSignups.purchased ? (
            <>
              <Button
                variant="secondary"
                size="small"
                icon={<EditIcon color={charcoal} ratio={0.5} />}
                onClick={() => handleEditClick(yourSignups)}
                data-qa-id="edit-signup"
              />
              <Link
                as="primary"
                href={productLink.affiliate_url}
                variant="small"
                data-qa-id="purchase"
                target="_blank"
              >
                Purchase
              </Link>
            </>
          ) : (
            <Button
              variant="secondary"
              size="small"
              data-qa-id="edit-signup"
              onClick={() => handleEditClick(yourSignups)}
            >
              Edit signup
            </Button>
          )}
        </div>
      );
    }
    const slotSkeleton = {
      signup_option: uuid,
      user_id: '',
      name: '',
      email: '',
      comment: '',
    };
    if (hasOpenSlots) {
      return (
        <SignUpButton
          toggleSelected={toggleSelected}
          slot={slotSkeleton}
          isSelected={selectedForPendingSignup}
          expired={expired}
        />
      );
    }
    return null;
  }, [yourSignups, productLink, expired, hasOpenSlots, toggleSelected, selectedForPendingSignup]);

  return (
    <div
      className={cx('signup-sheet__volunteer-signup-row', {
        expired,
        'signup-sheet__volunteer-signup-row--full': !hasOpenSlots,
      })}
      style={{
        backgroundColor: !hasOpenSlots && !yourSignups ? fog : slotColor,
        borderLeftColor: !hasOpenSlots && !yourSignups ? pebble : slotLine,
      }}
      data-qa-id="signup-count"
      data-qa-value={quantity || slots.length}
    >
      <div className="volunteer-signup-row__header">
        <div className="volunteer-signup-row__title">
          <SlotHeading
            split={split}
            slots={slots}
            item={item}
            startDate={startDate}
            past={past}
            allExpired={allExpired}
            isToday={isToday}
            signedUp={yourSignups}
          />
          <div className="volunteer-signup-row__slot-actions__wrapper">
            {SlotActions}
            {hasOpenSlots && (
              <Typography
                variant={isLargishAndUp ? 'paragraph3' : 'paragraph4'}
                className="volunteer-signup-row__available__filled"
                color={hasOpenSlots ? 'emeraldWebText' : 'coin'}
                semibold
                data-qa-id="signup-filled"
                data-qa-value={rowSignups.length}
              >
                {rowSignups.length} of {quantity || slots.length} filled
              </Typography>
            )}
            {((!expired && !hasOpenSlots && !yourSignups) || past) && (
              <Typography
                variant="paragraph2"
                semibold
                className="volunteer-signup-row__available__filled"
                color="coin"
                data-qa-id="signup-filled"
                data-qa-value={rowSignups.length}
              >
                {past ? 'Signup Passed' : 'All full'}
              </Typography>
            )}
          </div>
        </div>
        <div className="volunteer-signup-row__available">
          {((split && !hideForLegacyDateTime) || !split) && description && (
            <Typography
              variant={isLargishAndUp ? 'paragraph2' : 'paragraph3'}
              color="pebble"
              data-qa-id="signup-description"
            >
              {description}
            </Typography>
          )}
        </div>
      </div>
      {!expired && (
        <VolunteerLinkPreview
          uuid={uuid}
          productLink={productLink}
          isOrganizer={isOrganizer}
          organizerName={organizerName}
          disabled={!isOrganizer && !yourSignups}
          endAdornment={PurchasedControllerView}
        />
      )}
    </div>
  );
};

const SlotTitle = ({split, slot, item, startDate, signedUp}) => {
  const allDay = isAllDay(slot?.start_time, slot?.end_time);
  const hasTimeOrTitle = (!allDay && slot?.start_time) || slot?.title;
  const isLargishAndUp = useIsLargishAndUp();

  if (split && hasTimeOrTitle) {
    const hasTimeAndTitle = !allDay && slot?.start_time && slot?.title;
    const timeOrTitle = allDay && slot.title ? slot.title : `${slot.start_time} - ${slot.end_time}`;

    return (
      <div className="volunteer-signup-row__title-date">
        <Typography
          variant={isLargishAndUp ? 'header5' : 'list2'}
          semibold
          className="volunteer-signup-row__slot-title"
          data-qa-id="signup-title"
          data-qa-value={timeOrTitle}
        >
          {timeOrTitle}{' '}
          {signedUp && (
            <CheckmarkCircle
              ratio={0.5}
              color={emerald}
              className="volunteer-signup-row__checkmark"
            />
          )}
        </Typography>
        {hasTimeAndTitle && (
          <Typography
            variant="list2"
            semibold
            className="volunteer-signup-row__slot-subtitle"
            data-qa-id="signup-subtitle"
            data-qa-value={slot.title}
          >
            {slot.title}
          </Typography>
        )}
      </div>
    );
  }
  if (item) {
    return (
      <Typography
        variant={isLargishAndUp ? 'header5' : 'paragraph1'}
        semibold
        size="large"
        data-qa-id="signup-title"
        data-qa-value={item}
        className="volunteer-signup-row__slot-title"
      >
        {item}
      </Typography>
    );
  }

  return (
    <Typography
      variant="header5"
      semibold
      className="volunteer-signup-row__slot-title"
      data-qa-id="signup-title"
      data-qa-value={startDate}
    >
      {formatDateShort(startDate) || '[Date]'}
    </Typography>
  );
};

const SlotHeading = ({split, slot, item, startDate, signedUp}) => (
  <div className="volunteer-signup-row__slot-heading">
    <SlotTitle split={split} slot={slot} item={item} startDate={startDate} signedUp={signedUp} />
  </div>
);

const Pill = ({expired, ...props}) => (
  <Typography
    className={cx('page-section__status-pill', {expired})}
    variant="label2"
    as="span"
    inline
  >
    {props.children}
  </Typography>
);
