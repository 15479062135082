import {Fragment, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {
  selectCalendarLinks,
  selectEndDate,
  selectIsOrganizer,
  selectSheetId,
  selectSheetType,
  selectSignupOptions,
  selectStartDate,
  selectWishlistOption,
  selectWishlists,
  selectYourSignups,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {generateMd5Hash, generateSha256Hash} from '~/common/utils/hash';
import {cleanupROKTOverlay, triggerROKTOverlay} from '~/common/utils/ROKT';
import {selectVolunteerEmail} from '~/common/fabric/User/reducers/user';
import {
  addAffiliateTagsToWishlist,
  formatDateShort,
  formatDateRange,
  getBrandNameAndIcon,
  isAllDay,
  normalizeUrl,
} from '~/SignUpSheets/utils/misc';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {VendorDisc} from '~/common/_pb_components/molecules/VendorDisc';
import {
  BLUEPRINT_TYPE_DATETIME,
  BLUEPRINT_TYPE_ITEMS,
} from '~/SignUpSheets/reducers/blueprint/constants';
import {AddToCalendar} from '~/common/_pb_components/molecules/AddToCalendar';
import {
  selectBlueprintId,
  selectIsDateTimeBlueprint,
} from '~/SignUpSheets/reducers/blueprint/selectors';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';
import {AffiliateIcons} from '~/SignUpSheets/utils/affiliate';
import {LinkPreview} from '~/SignUpSheets/components/LinkPreview/LinkPreview';

import {largishAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';
import '~/SignUpSheets/Create/routes/HostConfirm/HostConfirm.sass';
import './VolunteerConfirm.sass';

const SignUpItem = ({
  blueprintType,
  item,
  date,
  description,
  startTime,
  endTime,
  timeSlotDescription,
  calendarLinks,
  count,
  isOrganizer,
  comment = '',
  product = {},
  title,
}) => {
  const allDay = isAllDay(startTime, endTime);
  return (
    <div className="signup-item__bp-item">
      <div className="signup-item__text-center">
        <div className="signup-item__heading">
          {(item || date) && (
            <Typography variant="paragraph1" as="h3">
              {item || formatDateShort(date)}
            </Typography>
          )}
          {description && (
            <Typography variant="paragraph3" color="pebble">
              {description}
            </Typography>
          )}
        </div>
        {(title || startTime || timeSlotDescription) && (
          <div className="signup-item__body">
            {title && (
              <Typography variant="paragraph3" semibold>
                {title}
              </Typography>
            )}
            {startTime && !allDay && (
              <Typography variant="paragraph3">
                {startTime} - {endTime}
              </Typography>
            )}
            {timeSlotDescription && (
              <Typography variant="paragraph3" color="pebble">
                {timeSlotDescription}
              </Typography>
            )}
          </div>
        )}
        {comment && (
          <Typography variant="paragraph3" color="pebble">
            {`You commented: "${comment}"`}
          </Typography>
        )}
        {(count || product.url) && (
          <div className="signup-item__product-and-quantity">
            {product.affiliate_url &&
              (isOrganizer || !product.not_safe) &&
              (product.vendor_name ? (
                <img
                  src={
                    product.images?.primary?.medium?.url ?? AffiliateIcons.get(product.vendor_name)
                  }
                  className="signup-item__product-image"
                  alt={product.title ?? product.vendor_name}
                />
              ) : (
                <LinkPreview href={product.affiliate_url} displayText={product.display_text} />
              ))}
            <div className="signup-item__quantity-and-price">
              {count && blueprintType !== BLUEPRINT_TYPE_DATETIME && (
                <Typography variant="list2" size="small">
                  {item ? 'Quantity' : 'Signups'}: {count}
                </Typography>
              )}
              {product.price?.display_amount && (
                <Typography variant="paragraph4" color="pebble">
                  {product.price.display_amount}
                </Typography>
              )}
            </div>
          </div>
        )}
        {product.vendor_name && (
          <Link
            href={product.affiliate_url}
            target="_blank"
            as="primary"
            variant="small"
            data-qa-id="purchase-now"
          >
            Purchase now
          </Link>
        )}
        <AddToCalendar
          className="confirm-page__add-to-calendar-btn"
          data-qa-id="add-to-calendar-link"
          closeMenu={() => {}}
          isButton
          googleCalUrl={calendarLinks.google_cal_url}
          iCalUrl={calendarLinks.ical_url}
          outlookUrl={calendarLinks.outlook_cal_url}
          type="signup-sheet"
          buttonSize="large"
        />
      </div>
    </div>
  );
};

const SignUpContainer = ({
  blueprintType,
  signupOptions = [],
  signups = [],
  userId,
  volViewHref,
  startDate,
  isOrganizer,
}) => {
  const {linkPreviews} = useAffiliateActions();

  const orderedSignupIds = useMemo(() => signupOptions.map((s) => s.uuid), [signupOptions]);
  const sortedAndFilteredSignups = useMemo(
    () =>
      signups
        .toSorted((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        .map((s) => ({
          ...s,
          product: linkPreviews[s.option],
        })),
    [signups, orderedSignupIds, userId, linkPreviews]
  );
  const yourSignups = useMemo(
    () =>
      signupOptions
        .map((s) => ({
          item: s.item,
          title: s.slots?.find((i) => i.slot_id === s.slot_id)?.title,
          date: s.date,
          option: s.slot_id || s.uuid,
          description: s.description,
          start_time: s.start_time,
          end_time: s.end_time,
          slot_description: s.slot_description,
          signups: sortedAndFilteredSignups.filter(
            (item) => (!item.slot_id || item.slot_id === s.slot_id) && item.signup_option === s.uuid
          ),
          product: {
            ...(s.product_link ?? {}),
            ...linkPreviews[s.slot_id || s.uuid],
          },
        }))
        .filter((s) => s.signups.length > 0)
        .map((s) => ({
          ...s,
          count: s.signups.length,
          signupWithComment: s.signups.map((signup) => signup.comment).pop(),
          calendarLinks: s.signups.length > 0 ? s.signups[0].calendar_links : undefined,
        })),
    [signupOptions, sortedAndFilteredSignups, linkPreviews]
  );

  // eslint-disable-next-line arrow-body-style
  const SignUpsView = useMemo(() => {
    return yourSignups.map((signup, index) => (
      <Fragment key={signup.option}>
        {index > 0 && <hr className="signup-sheet__volunteer-confirm__separator" />}
        <SignUpItem
          title={signup.title}
          blueprintType={blueprintType}
          item={signup.item}
          count={signup.count}
          comment={signup.signupWithComment?.comment}
          product={signup.product}
          isOrganizer={isOrganizer}
          date={signup.date || startDate}
          description={signup.description}
          startTime={signup.start_time}
          endTime={signup.end_time}
          calendarLinks={signup.calendarLinks ?? signup.calendarLinks ?? {}}
          timeSlotDescription={signup.slot_description}
        />
      </Fragment>
    ));
  }, [yourSignups, isOrganizer]);

  return (
    <div className="confirm-page__section">
      {SignUpsView}

      <Link as="secondary" data-qa-id="footer-view-sheet-link" href={volViewHref} variant="small">
        <Typography variant="paragraph3" semibold>
          View SignUp Sheet
        </Typography>
      </Link>
    </div>
  );
};

export const VolunteerConfirm = () => {
  const {state: {isEditing} = {isEditing: false}, search} = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    userId: loggedInUserId,
    emailMd5: stateEmailMd5,
    emailSha256: stateEmailSha256,
  } = useSelector((state) => state?.user);
  const userId = atob(queryParams.get('uid')) || loggedInUserId;

  const signups = useSelector(selectYourSignups(userId), shallowEqual);
  const volunteerEmail = useSelector(selectVolunteerEmail);
  const signupOptions = useSelector(selectSignupOptions, shallowEqual);
  const wishlistOption = useSelector(selectWishlistOption);
  const blueprintId = useSelector(selectBlueprintId);
  const blueprintType = useSelector(selectSheetType);
  const isDateTimeType = useSelector(selectIsDateTimeBlueprint);
  const wishlists = useSelector(selectWishlists, shallowEqual);
  const sheetId = useSelector(selectSheetId);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const calendarLinks = useSelector(selectCalendarLinks, shallowEqual);
  const isOrganizer = useSelector(selectIsOrganizer);
  const {matches: isDesktop} = useMatchQuery(`(${largishAndUpQuery})`);
  const isCustomWishlist = wishlistOption === 'my-wishlist';

  const isItemsType = useMemo(() => blueprintType === BLUEPRINT_TYPE_ITEMS, [blueprintType]);

  const [emailMd5, setEmailMd5] = useState(stateEmailMd5);
  const [emailSha256, setEmailSha256] = useState(stateEmailSha256);

  const roktEmail = volunteerEmail?.toLowerCase();

  const roktAttributes = {email: roktEmail, userId: loggedInUserId, emailMd5, emailSha256};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetches and sets the MD5 and SHA-256 hashes for the email
  useEffect(() => {
    const fetchHashes = async () => {
      const md5 = loggedInUserId ? stateEmailMd5 : generateMd5Hash(roktEmail);
      const sha256 = loggedInUserId ? stateEmailSha256 : await generateSha256Hash(roktEmail);
      setEmailMd5(md5);
      setEmailSha256(sha256);
    };
    fetchHashes();
  }, [loggedInUserId, roktEmail, stateEmailMd5, stateEmailSha256]);

  // Triggers ROKT overlay when email hashes are available
  useEffect(() => {
    if (emailMd5 && emailSha256) {
      triggerROKTOverlay('signupsheet', roktAttributes);
      return cleanupROKTOverlay;
    }
    return () => {};
  }, [emailMd5, emailSha256]);

  const volViewHref = useMemo(
    () => (sheetId ? `/signup-sheet/${[sheetId, search].join('/')}` : ''),
    [sheetId]
  );

  const signupOptionsWithSlotDetails = useMemo(() => {
    if (isDateTimeType) {
      return signupOptions.flatMap((o) =>
        o.slots.map((slot) => ({
          ...o,
          slot_id: slot.slot_id,
          start_time: slot.start_time,
          end_time: slot.end_time,
          slot_description: slot.description,
          product_link: slot.product_link,
        }))
      );
    }
    return signupOptions;
  }, [signupOptions, isDateTimeType]);

  return (
    <div className="sign-up-sheets__confirm-page">
      <div className="confirm-page__header">
        <Typography variant={isDesktop ? 'header1' : 'header2'} as="h1">
          {isEditing ? 'Your changes are saved!' : "You're all signed up!"}
        </Typography>
        {isEditing && (
          <Link variant="small" data-qa-id="view-sheet-link" href={volViewHref}>
            View Sheet
          </Link>
        )}
      </div>
      {!isEditing && (
        <SignUpContainer
          blueprintType={blueprintType}
          signupOptions={signupOptionsWithSlotDetails}
          signups={signups}
          userId={userId}
          volViewHref={volViewHref}
          startDate={startDate}
          isOrganizer={isOrganizer}
        />
      )}
      <div className="confirm-page__section">
        <div>
          <Typography
            className="confirm-page__title"
            variant={isDesktop ? 'header2' : 'header3'}
            as="h2"
          >
            {isCustomWishlist
              ? 'Get something from their wishlist'
              : 'Need something for your signup?'}
          </Typography>
          {isCustomWishlist && (
            <Typography className="confirm-page__subtitle" variant="paragraph2" color="pebble">
              Your organizer added a wishlist—check it out!
            </Typography>
          )}
        </div>
        {isCustomWishlist ? (
          <div className="confirm-page__vendor-discs">
            {wishlists.map((wishlist) => {
              const {brandName, iconUrl} = getBrandNameAndIcon(wishlist);

              return (
                <VendorDisc
                  key={wishlist}
                  imgSrc={iconUrl}
                  displayText="View wishlist"
                  vendorName={brandName}
                  className={`vendor-disc__wrapper--${brandName.toLowerCase() || 'default'}`}
                  dataQaId={`${brandName}-vendor-disc`}
                  href={addAffiliateTagsToWishlist(normalizeUrl(wishlist || '#'), blueprintId)}
                  textColor="tanzanite"
                  semibold
                />
              );
            })}
          </div>
        ) : (
          <VendorDisc
            imgSrc="https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Amazon@2x.png"
            href={addAffiliateTagsToWishlist('https://www.amazon.com/', blueprintId, false)}
            displayText="Shop now"
            vendorName="Amazon"
            textColor="tanzanite"
            semibold
            size="large"
          />
        )}
      </div>
      {isItemsType && (
        <div className="confirm-page__section">
          <Typography
            className="confirm-page__title"
            variant={isDesktop ? 'header2' : 'header3'}
            as="h2"
          >
            More details
          </Typography>
          <Typography variant="paragraph1">{formatDateRange(startDate, endDate)}</Typography>

          <div className="confirm-page__add-to-calendar-wrapper">
            <AddToCalendar
              data-qa-id="add-to-calendar-link"
              closeMenu={() => {}}
              isButton
              googleCalUrl={calendarLinks.google_cal_url}
              iCalUrl={calendarLinks.ical_url}
              outlookUrl={calendarLinks.outlook_cal_url}
              type="signup-sheet"
              buttonSize="large"
            />
          </div>
        </div>
      )}
    </div>
  );
};
