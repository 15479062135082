import {useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {pluralize} from '~/common/utils';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {selectIsOrganizer} from '~/SignUpSheets/reducers/sheet/selectors';

import './FilledSlot.sass';

export const FilledSlot = ({name, comment, email, matchingUser, signups}) => {
  const isOrganizer = useSelector(selectIsOrganizer);
  const isLargishAndUp = useIsLargishAndUp();

  return (
    <div className="filled-slot__container">
      <div className="filled-slot__item">
        <div
          className="filled-slot__name-comment-wrapper"
          data-qa-id="signup-row-user"
          data-qa-value={name}
        >
          <div>
            <Typography variant="list2" size="large" as="span" data-qa-id="signup-row-name">
              {name}
            </Typography>
            {matchingUser && (
              <Typography variant="paragraph2" as="span">
                {' '}
                (you)
              </Typography>
            )}
            {comment && isLargishAndUp && (
              <Typography
                variant="paragraph3"
                size="large"
                color="pebble"
                data-qa-id="signup-row-comment"
                data-qa-value={comment}
              >
                &quot;{comment}&quot;
              </Typography>
            )}
          </div>
          {isOrganizer && email ? (
            <Typography
              variant="list1"
              color="pebble"
              data-qa-id="signup-row-email"
              data-qa-value={email}
            >
              {email}
            </Typography>
          ) : null}
        </div>
        <Typography color="emeraldWebText" variant="list1" size="small">
          {pluralize('slot', signups)}
        </Typography>
      </div>
    </div>
  );
};
