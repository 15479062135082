import {Button} from '~/common/_pb_components/atoms/Button';
import {ConfirmLeaveModal} from '~/CreateFabricInvitation/navigation/ConfirmLeaveModal';
import './LeaveModal.sass'; // TODO Refactor

export const CreateFlowLeaveModal = (props) => {
  const {saveAndLeave, leave, saving} = props;
  return (
    <ConfirmLeaveModal
      {...props}
      title="Are you sure you want to Exit?"
      message="Do you want to save your progress?"
    >
      <Button
        className="create-flow__upsell-modal-back-btn"
        data-qa-id="say-no-btn"
        variant="primary"
        size="large"
        onClick={saveAndLeave}
        loading={saving}
        disabled={saving}
      >
        Save Draft
      </Button>
      <Button
        className="create-flow__upsell-modal-upgrade-btn"
        data-qa-id="say-yes-btn"
        variant="secondary"
        size="large"
        onClick={leave}
        data-impression-tracking="true"
        loading={saving}
        disabled={saving}
      >
        Exit
      </Button>
    </ConfirmLeaveModal>
  );
};

export const EditFlowLeaveModal = (props) => {
  const {cancelLeave, leave} = props;
  return (
    <ConfirmLeaveModal
      {...props}
      title="Are you sure you want to exit?"
      message="Your unsaved changes will be lost."
    >
      <Button
        className="create-flow__upsell-modal-upgrade-btn"
        data-qa-id="say-yes-btn"
        variant="secondary"
        size="large"
        onClick={cancelLeave}
        data-impression-tracking="true"
      >
        Stay
      </Button>
      <Button
        className="create-flow__upsell-modal-back-btn"
        data-qa-id="say-no-btn"
        variant="primary"
        size="large"
        onClick={leave}
      >
        Exit
      </Button>
    </ConfirmLeaveModal>
  );
};

export const RemoveActiveSlotModal = ({cancel, remove}) => (
  <ConfirmLeaveModal
    title="Remove slot"
    message="Are you sure? Volunteer(s) will be notified via email that their slot was removed."
    cancelLeave={cancel}
  >
    <Button
      className="create-flow__upsell-modal-upgrade-btn"
      data-qa-id="say-yes-btn"
      variant="secondary"
      size="large"
      onClick={cancel}
      data-impression-tracking="true"
    >
      Keep slot
    </Button>
    <Button
      className="create-flow__upsell-modal-back-btn"
      data-qa-id="say-no-btn"
      variant="primary"
      size="large"
      onClick={remove}
    >
      Remove slot
    </Button>
  </ConfirmLeaveModal>
);
