import {useSelector} from 'react-redux';
import cx from 'classnames';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {LocationIcon} from '~/common/svg/LocationIconAlt';
import {formatDateShort} from '~/SignUpSheets/utils/misc';
import {useFormattedAddress} from '~/SignUpSheets/components/Details/hooks/useFormattedAddress';
import {selectIsPreviewMode} from '~/SignUpSheets/reducers/sheet/selectors';

export const SplitViewLeftColumn = ({dateSlot, past, allExpired, isToday}) => {
  const hasDateOrTitle = dateSlot.date || dateSlot.title;
  const hasDateAndTitle = dateSlot.date && dateSlot.title;
  const cityStateZip = useFormattedAddress();
  const isPreviewMode = useSelector(selectIsPreviewMode);

  return (
    <div className="split-page-section__slot__date-column">
      {hasDateOrTitle && (
        <Typography variant="header5" semibold className="split-page-section__slot__date">
          {dateSlot.date ? formatDateShort(dateSlot.date) || '[Date]' : dateSlot.title}
          {past && !allExpired && <Pill expired>past</Pill>}
          {isToday && !past && <Pill>today</Pill>}
        </Typography>
      )}
      {hasDateAndTitle && (
        <Typography variant="header6" semibold className="split-page-section__slot__title">
          {dateSlot.title}
        </Typography>
      )}
      {dateSlot.description && (
        <Typography variant="paragraph3" color="pebble" className="split-page-section__slot__desc">
          {dateSlot.description}
        </Typography>
      )}
      {(cityStateZip || isPreviewMode) && (
        <div className="split-page-section__slot__location">
          <LocationIcon ratio={0.5} className="split-page-section__slot__location__icon" />
          <Typography variant="paragraph3" color="pebble">
            {cityStateZip || '[Location]'}
          </Typography>
        </div>
      )}
    </div>
  );
};

const Pill = ({expired, children}) => (
  <Typography
    as="span"
    className={cx('page-section__status-pill', {expired})}
    variant="label2"
    inline
  >
    {children}
  </Typography>
);
